import React from 'react';
import classes from './ConfirmationForgotPasswordMail.module.css' 

export default function ConfirmationForgotPasswordMail(props){
    return(
        <>      
            <div className={classes.Container}>
                    <div className={classes.Content}>
                        <div>
                            <h3>
                                {
                                    props.language === 'de' 
                                    ?
                                    <>SETZEN SIE IHR PASSWORT ZURÜCK</>
                                    :
                                    <>RESET YOUR PASSWORD</>
                                }
                            </h3>
                        </div>
                        <div>
                            <i className='icon-confirm icon-lock'></i>
                        </div>
                        <div className='body_text'>
                            <div>
                                
                                {
                                    props.language === 'de' 
                                    ?
                                    <>Ihr Passwort wurde erfolgreich zurückgesetzt</>
                                    :
                                    <>Your password has been reset succesfully</>
                                }
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

