import React from 'react';

import classes from './ConfirmationForgotPassword.module.css' 

export default function ConfirmationForgotPassword(props){
   
    return(
        <>
            <div className={classes.container}>
                <div className={classes.content}>
                    <div>
                        <h3>
                            {
                                props.language === 'de' 
                                ?
                                <>SETZEN SIE IHR PASSWORT ZURÜCK</>
                                :
                                <>RESET YOUR PASSWORD</>
                            }
                        </h3>
                    </div>
                    <div>
                        <i className='icon-confirm icon-send'></i>
                    </div>
                    <div className='body_text'>
                        <div>
                            {
                                props.language === 'de' 
                                ?
                                <>Wir haben Ihnen eine E-Mail gesendet, um Ihr Passwort zurückzusetzen.</>
                                :
                                <>We sent you an e-mail to reset your password.</>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
    
}