import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import classes from '../Pages/AboutAuthorPage.module.css'


export default function AboutAuthorPage({language}){

    function createMarkup(str) {
        return {__html: str};
    }

    const [pageContent, setPageContent] = useState({})

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/about-author/?locale=${language}&populate=*`, { 
            'Content-Type': 'application/json' , method: 'GET' 
            })
            .then(data => data.json())
            .then(data => setPageContent(data.data.attributes))
    }, [language])

    return(
        <>
            <section>
                <div className={classes.AuthorIntro}>
                    <div>
                        <div className={`${classes.AuthorIntroTitle} fade-center-top`}>
                            <h1>{pageContent.title_1}</h1>
                        </div>
                        <div className={classes.AuthorIntroText} >
                            <div dangerouslySetInnerHTML={createMarkup(pageContent.subtitle_1)} />
                        </div>
                        <div className={classes.AuthorIntroImageContainer }>
                            <img src={`https://strapi.accounting-a-z.ch${{...{...{...pageContent.aldo_portrait_image}.data}.attributes}.url}`} alt="" className={classes.AuthorIntroImage} />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className={classes.AuthorAbout} >
                    <div className={classes.AuthorAboutContent}>
                        <div >
                            <h3 >
                                {pageContent.title_2}
                            </h3>
                        </div>
                        <div >
                            <div className='body_text'>
                                <div dangerouslySetInnerHTML={createMarkup(pageContent.text_1)} />
                            </div>
                        </div>
                        {/* <div className={classes.AuthorAboutCallToAction}>
                            <Link
                                to={`/registration`}
                                className={'btn ochre'}
                            >
                                {pageContent.knowmore_button_text}
                            </Link>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    );

}