import React, { useEffect, useState } from 'react';
import SvgPatternBackground from '../Components/svgPatternBackground';
import classes from './PrivacyNotice.module.css'

export default function PrivacyNotice({language}) {

    function createMarkup(str) {
        return {__html: str};
    }

    const [contentHeight, setContentHeight] = useState()
    const [pageContent, setPageContent] = useState({})

    useEffect(() => {
        if (window.location.pathname.startsWith('/dictionary/') || window.location.pathname.startsWith('/openDictionary/')) {
            document.getElementById("body").style.overflowY = "hidden";  
        } else {
            document.getElementById("body").style.overflowY = "scroll";
        }
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/privacy-notice/?locale=${language}`, { 
            'Content-Type': 'application/json' , method: 'GET' 
            })
            .then(data => data.json())
            .then(data => setPageContent(data.data.attributes))
            .then(() => setContentHeight(document.getElementById("content").getBoundingClientRect().height))
    }, [language])

    return(
        <>
        <section height={contentHeight} className={classes.privacyNoticeContainer}>
            <SvgPatternBackground 
                scale={window.innerWidth < 600 ? '1.5' : '1'}
                number={1} 
                height={contentHeight} 
                backgroundColor={'#e0f2f0'} 
                color={'d7eeeb'} 
                angle={0}
            />
            <div className={classes.privacyNotice} id='content'>
                <h1 className={classes.title}>{pageContent.privacy_policy_title}</h1>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_1}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_1)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_2}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_2)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_3}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_3)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_4}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_4)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_5}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_5)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_6}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_6)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_7}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_7)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_8}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_8)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_9}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_9)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_10}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_10)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_11}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_11)} />
                </div>
                <div>
                    <h4 className={classes.subtitle}>{pageContent.title_12}</h4>
                    <div dangerouslySetInnerHTML={createMarkup(pageContent.text_12)} />
                </div>
            </div>
        </section>
        </>
    )
}