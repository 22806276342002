import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import classes from './AppFooter.module.css'
export default function AppFooter({language}){
    useEffect(() => {
        document.getElementById("year").innerHTML = new Date().getFullYear()
    },[])
    return(
        <footer className={classes.footerContainer} style={{ display: window.innerWidth < 900 ? 'none' : 'block'}}>
           
                    <div className={classes.imprint}>
                        <div >
                            <Link className={classes.imprintLink} to={`/termsOfUse`}>
                                 
                                {
                                    language === "de"
                                    ?
                                    <>NUTZUNGSBEDINGUNGEN</>
                                    :
                                    <>TERMS OF USE</>
                                }
                            </Link> <span style={{color:'white', margin:'0 15px'}}>|</span> 
                            <Link className={classes.imprintLink} to={`/privacyNotice`}> 
                                
                                {
                                    language === "de"
                                    ?
                                    <>DATENSCHUTZERKLÄRUNG</>
                                    :
                                    <>PRIVACY POLICY</>
                                }
                            </Link> <span style={{color:'white', margin:'0 15px'}}>| </span>
                            <Link className={classes.imprintLink} to={`/imprint`}>
                                
                                {
                                    language === "de"
                                    ?
                                    <>IMPRESSUM</>
                                    :
                                    <>IMPRINT</>
                                }
                            </Link>
                        </div>
                        <p className={classes.imprintCopyright}>Accounting A-Z Ⓒ <span id="year"></span></p>
                    </div>
            
        </footer>
    );
}