import React from 'react';
import classes from './CheckboxInput.module.css'

export default function CheckboxInput({selected, onChange = f=> f, text, value, disabled}) {

    return(
        <>
            <div 
                style={{pointerEvents: disabled ? 'none': 'all', marginTop:'25px'}}
                className={classes.modernRadioContainer}
                onClick={() => {onChange(value); }}
            >
                <div
                    className={`${classes.radioOuterCircle } ${value === false && classes.unselected }`}
                    style = {{border: disabled ? '1px solid #dbb480' : '1px solid #B66A00', backgroundColor: disabled && value === true ? '#F3BF4C' : disabled && value === false ? 'transparent' : '#F3BF4C'}}
                >
                <div
                    className={`${classes.radioInnerCircle} ${Object.values(value)[0] !== Object.values(selected)[0] && classes.unselectedCircle}`}
                    
                >
                    <svg style={{display: value === false ? 'none' : 'block'}} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 8L4 11L11 1" stroke={ disabled ?"#dbb480":'#B66A00'}stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                </div>
                <div style={{marginLeft: '10px', color: disabled ? '#bcbcbc' : '#292929', fontFamily:'Roboto', maxWidth:'300px'}} className={classes.helperText}>{text}</div>
            </div>
        </>
        
    );
}