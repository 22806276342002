import React from 'react';

import classes from '../Pages/ConfirmationRegistrationMail.module.css'

export default function ConfirmationRegistrationMail(props) {
    return(
        <>
        <div className={classes.ConfirmationContainer}>
            <div className={classes.Confirmation}>
                <div>
                    <h3>WELCOME</h3>
                </div>
                <div >
                    <i className='icon-confirm icon-speaker'></i>
                </div>
                <p className='body_text'>
                    <div>
                        Your account was created successfully
                    </div>
                    <div>
                        Please confirm your email to activate your account.
                    </div>
                </p>
            </div>
        </div>
        </>
    )
}