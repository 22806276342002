import { useEffect, useRef, useState } from "react";

export default ({ root = null, rootMargin, threshold = 0 }) => {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);

  const observer = useRef(
    new window.IntersectionObserver(([entry]) => updateEntry(entry), {
      root,
      rootMargin,
      threshold
    })
  );

  useEffect(
    () => {
      const { current: currentObserver } = observer;
      currentObserver.disconnect();

    //   document.addEventListener("visibilitychange", function() {
    //     if (document.hidden) { 
    //       player.pauseVideo();
    //     } else {
    //       player.playVideo();
    //     }
    //   });

        if (node) {
            currentObserver.observe(node);
            fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/addactiveuser/${window.localStorage.getItem("id")}`, { 
               method: 'GET' 
            })
            
        }

      return () => {
        currentObserver.disconnect();
      }
    },
    [node]
  );

  return [setNode, entry];
};