import React, {useEffect, useState, useRef} from 'react';
import useIntersect from '../Hooks/useIntersect';

import AppFooter from '../Components/AppFooter';
import AppHeader from '../Components/AppHeader';

import Terms from '../Components/Terms';
import Meaning from '../Components/Meaning';

import Toast from '../Components/Toast2';
import RadioInput from '../Components/RadioInput'
import AdsComponent from '../Components/AdComponent';
import AdComponentVertical from '../Components/AdComponentVertical'

import {useHistory} from 'react-router-dom'




import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import classes from './DictionaryWithAds.module.css'

const parseJSON = (resp) => (resp.json ? resp.json() : resp);
const checkStatus = (resp) => {
    if (resp.status >= 200 && resp.status < 300) {
      return resp;
    }
  
    return parseJSON(resp).then(resp => {
      throw resp;
    });
};

const headers = { 'Content-Type': 'application/json' };





export default function Dictionary(props) {
    const [isLoadingTerms, setIsLoadingTerms] = useState(false) // 1
    const [isLoadingMeaning, setIsLoadingMeaning] = useState(false) // 2
    const [isLoadingMeaningTranslation, setIsLoadingMeaningTranslation] = useState(false) // 3
    const [isLoadingPredictedTerms, setIsLoadingPredictedTerms] = useState(false) // 4
    const [loader, setLoader] = useState(false) // 5
    const [page, setPage] = useState(1)  // 6
    const [termNotSelected, setTermNotSelected] = useState(true) // 7
    const [terms, setTerms] = useState([])  // 8
    const [termsMeta, setTermsMeta] = useState() // 9
    const [scrolledCount, setScrolledCount] = useState(2) // 10
    const [newFilterSearch, setNewFilterSearch] = useState(false) // 11
    const [reloaded, setReloaded] = useState(true) // 12
    const [currentSearch, setCurrentSearch] = useState('') // 13
    const [currentLetters, setCurrentLetters] = useState(['A','B','C']) // 14
    const [currentLetter, setCurrentLetter] = useState('') // 15
    const [displayedLetters, setDisplayedLetters] = useState(false) // 16
    const [displayedSearchBarOptions, setDisplayedSearchBarOptions] = useState(false) // 17
    const [meaning, setMeaning] = useState([]) // 18
    const [meaningHasSynonym, setMeaningHasSynonym] = useState(false) // 19
    const [meaningTranslation, setMeaningTranslation] = useState(false) // 20
    const [searched, setSearched] = useState('') // 21
    const [searchLanguage, setSearchLanguage] = useState('de') // 22
    const [displayedPredicted, setDisplayedPredicted] = useState(false) // 23
    const [predictedTerms, setPredictedTerms] = useState([]) // 24
    const [clipboard, setClipboard] = useState('') // 25
    const [toastVisible, setToastVisible] = useState(false) // 26
    const [clipboardTitle, setClipboardTitle] = useState('') // 27
    const [toastProperties, setToastProperties] = useState([]) // 28
    const [link, setLink] = useState([]) // 29
    const [mobile, setMobile] = useState(false) // 30
    const [searchBarDisplayed, setSearchBarDisplayed] = useState(false) // 31
    const [mobileMeaningStyle, setMobileMeaningStyle] = useState(false) // 32
    const [skeletonWidth, setSkeletonWidth] = useState() // 33
    const [selected, setSelected] = useState(['A','B','C']) // 34
    const [timeToFetch, setTimeToFetch] = useState(1000) // 35
    const [pageContent, setPageContent] = useState({})

    let termId = props.location.pathname.replace('/openDictionary/','')
    window.localStorage.setItem("termId", termId);



   

    const [ref, entry] = useIntersect({});


    // Working one
    useEffect(() => {
        // if (document.cookie.indexOf('all_cookie=') === -1){
        //     document.cookie = "__eoi=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        //     document.cookie = "__gads=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        //     document.cookie = "__gpi=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        // }
        

        if (window.location.pathname.startsWith('/openDictionary/')) {
            document.getElementById("body").style.overflowY = "hidden";  
            document.getElementById("body").style.position = 'fixed'
        } else {
            document.getElementById("body").style.overflowY = "scroll";
            document.getElementById("body").style.position = 'relative'
        }
        if(termId == 0) {
            window.innerWidth < 900 ? setMobile(true) : setMobile(false)
            setIsLoadingTerms(true)
            setMeaning([])
            fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/terms?fields[0]=id&fields[1]=text&fields[2]=free&fields[3]=locale&populate[synonyms][fields][0]=id&populate[synonyms][fields][1]=text&populate[synonyms][fields][3]=free&populate[synonyms][fields][4]=locale&populate[localizations][fields][0]=id&populate[localizations][fields][1]=text&populate[localizations][fields][3]=free&populate[localizations][fields][4]=locale&populate[localizations][populate][synonyms][fields][0]=id&populate[localizations][populate][synonyms][fields][1]=text&populate[localizations][populate][synonyms][fields][3]=free&populate[localizations][populate][synonyms][fields][4]=locale&sort[0]=text%3Aasc`, { 
                headers, method: 'GET' 
            })
            .then(data => data.json())
            .then(data => {setTerms(data.data); setIsLoadingTerms(false); setIsLoadingMeaning(false); setTermsMeta(data.meta)})
        }else{
            setTermNotSelected(false)
            window.innerWidth < 900 ? setMobile(true) : setMobile(false)
            setIsLoadingTerms(true)
            setIsLoadingMeaning(true)
            // fetch(`${process.env.REACT_APP_API_STRAPI_URL}/api/terms/${Number(termId)}?populate=*`, { 
            //     headers, method: 'GET' 
            // })
            fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/secterms/${Number(termId)}/1`, { 
                headers, method: 'GET' 
            })
                .then(checkStatus)
                .then(parseJSON)
                // .then(data => )
                .then(data  => {
                    // console.log(data)
                    setMeaning(data); 
                    // // console.log({...data.data.attributes}.text.slice(0, 1));

                    // setSearchLanguage({...data.attributes}.locale);
                    let currentLetters, currentLetter; 
                    currentLetter = data.text.slice(0, 1)
                    
                    if (currentLetter === 'A' || currentLetter==='B' || currentLetter==='C' || currentLetter === 'a' || currentLetter==='b' || currentLetter==='c'){
                        currentLetters = ["A", "B", "C"]
                    } else if (currentLetter === 'D' || currentLetter==='E' || currentLetter==='F' || currentLetter === 'd' || currentLetter==='e' || currentLetter==='f') {
                        currentLetters = ["D", "E", "F"]
                    } else if (currentLetter === 'G' ||  currentLetter==='H' ||  currentLetter==='I' || currentLetter === 'g' ||  currentLetter==='h' ||  currentLetter==='i') {
                        currentLetters = ["G", "H", "I"]
                    }else if (currentLetter === 'J' ||  currentLetter==='K' ||  currentLetter==='L' || currentLetter === 'j' ||  currentLetter==='k' ||  currentLetter==='l') {
                        currentLetters = ["J", "K", "L"]
                    }else if (currentLetter === 'M' ||  currentLetter==='N' ||  currentLetter==='O' || currentLetter === 'm' ||  currentLetter==='n' ||  currentLetter==='o' ) {
                        currentLetters = ["M", "N", "O"]
                    }else if (currentLetter === 'P' ||  currentLetter==='Q' ||  currentLetter==='R' || currentLetter === 'p' ||  currentLetter==='q' ||  currentLetter==='r') {
                        currentLetters = ["P", "Q", "R"]
                    }else if (currentLetter === 'S' ||  currentLetter==='T' ||  currentLetter==='U' || currentLetter === 's' ||  currentLetter==='t' ||  currentLetter==='u') {
                        currentLetters = ["S", "T", "U"]
                    }else if (currentLetter === 'V' ||  currentLetter==='W' ||  currentLetter==='X' || currentLetter === 'v' ||  currentLetter==='w' ||  currentLetter==='x') {
                        currentLetters = ["V", "W", "X"]
                    }else if (currentLetter === 'Y' ||  currentLetter==='Z' || currentLetter === 'y' ||  currentLetter==='z') {
                        currentLetters = ["Y", "Z"]
                    }

                    setCurrentLetters(currentLetters)
                    setSelected(currentLetters)
                    setCurrentLetter(currentLetter)
                    setCurrentSearch(data.text.slice(0, 4))
                
                    // fetch(`${process.env.REACT_APP_API_STRAPI_URL}/api/terms?populate[synonyms][fields][0]=text&populate[synonyms][fields][1]=definition&populate[localizations][populate][synonyms][fields][0]=text&populate[localizations][populate][synonyms][fields][1]=definition&populate[localizations][populate][synonyms][fields][2]=locale&populate[localizations][fields][0]=text&populate[localizations][fields][1]=definition&populate[localizations][fields][2]=locale&fields[0]=text&locale=${'de'}&fields[1]=definition&sort[0]=text%3Aasc&filters[text][$startsWith]=${data.text.slice(0, 4)}`, { 
                    //     headers, method: 'GET' 
                    // })
                    fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/terms?fields[3]=id&fields[1]=text&fields[2]=free&fields[4]=locale&populate[synonyms][fields][0]=id&populate[synonyms][fields][1]=text&populate[synonyms][fields][3]=free&populate[synonyms][fields][4]=locale&populate[localizations][fields][0]=id&populate[localizations][fields][1]=text&populate[localizations][fields][3]=free&populate[localizations][fields][4]=locale&populate[localizations][populate][synonyms][fields][0]=id&populate[localizations][populate][synonyms][fields][1]=text&populate[localizations][populate][synonyms][fields][3]=free&populate[localizations][populate][synonyms][fields][4]=locale&locale=${'de'}&sort[0]=text%3Aasc&filters[text][$startsWith]=${data.text.slice(0, 4)}`, { 
                        headers, method: 'GET' 
                    })
                    .then(data => data.json())
                    .then(data => {setTerms(data.data); setIsLoadingTerms(false); setIsLoadingMeaning(false); setTermsMeta(data.meta)})

                })
            
            }
        
    }, [])

    useEffect(() => {
        setToastProperties({
            description: `${clipboardTitle.toUpperCase()}`,
            borderColor: '#0A9DE4',
            icon: 'icon-success'
        })
    }, [clipboardTitle])

    // useEffect(() =>{
    //     if(!termNotSelected && !isLoadingMeaning && !meaningTranslation && meaning.free) {
    //         setTimeout(() => {
    //             let dangerouslySetInnerHTML = document.getElementById('dangerouslySetInnerHTML').getElementsByTagName("a")
    //             Object.values(dangerouslySetInnerHTML).map(a => {
    //                 a.classList.add('tooltipTop')
    //                 a.href="javascript:void(0)"
    //             })
    //             Object.values(document.getElementsByClassName('tooltipTop')).map(a => a.appendChild(document.createElement("span")) )
    //             let tooltipContainer = document.getElementById('dangerouslySetInnerHTML').getElementsByTagName("span")
    //             Object.values(tooltipContainer).map(a=>a.classList.add('tooltipTopText'))
    //             Object.values(tooltipContainer).map(a=>a.innerHTML =  `<a class="link" style="color:#B66A00 !important;cursor: pointer;" href="/registration">Register</a> to use link`)
    //             console.log("Again")
    //         }, timeToFetch);
    //     }
    // },[termNotSelected, timeToFetch, isLoadingMeaning, meaningTranslation])


  

    const getMeaning = (id) => {
        var start = window.performance.now();
        setIsLoadingMeaning(true)
        const requestOptions = {
            method: 'GET', 
        }
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/secterms/${id}/1`, requestOptions)
        .then((response) => {
            if(response.status !== "200"){
                let err = Error;
                err.Message = "Invalid response code: " + response.status;
            }
            return response.json();
        })
        .then((json) => {
            setIsLoadingMeaning(false)
            setMeaningTranslation(false)
            // console.log(json)
           
            if(id !== '0') {
                setMeaning(json)
                // console.log(json)
                var end = window.performance.now();
                var time = end - start;
                console.log(time)
                setTimeToFetch(time + 1000)
        
                
            }
            

        })
        
    }

    const getTermsWithLanguage = (lan) => {
        setIsLoadingTerms(true)
        // fetch(`${process.env.REACT_APP_API_STRAPI_URL}/api/terms?populate=*&locale=${lan}&filters[text][$startsWith]=${currentLetter}&sort[0]=text%3Aasc` , { 
        //     headers, method: 'GET' 
        // })
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/terms?populate[synonyms][fields][0]=text&populate[synonyms][fields][1]=definition&populate[localizations][populate][synonyms][fields][0]=text&populate[localizations][populate][synonyms][fields][1]=definition&populate[localizations][populate][synonyms][fields][2]=locale&populate[localizations][fields][0]=text&populate[localizations][fields][1]=definition&populate[localizations][fields][2]=locale&fields[0]=text&locale=${lan}&fields[1]=definition&sort[0]=text%3Aasc&filters[text][$startsWith]=${currentLetter}` , { 
            headers, method: 'GET' 
        })
          .then(checkStatus)
          .then(parseJSON)
          .then(( data ) => {setTerms(data.data); setIsLoadingTerms(false); setTermsMeta(data.meta)})
        //   .then(setIsLoading(false))
        //   .catch((error) => setError(error))
        
    }

    const getTermsWithLetter = (letter) => {
        setIsLoadingTerms(true)
        const requestOptions = {
            method: 'GET', 
      
        }

        // fetch(`${process.env.REACT_APP_API_STRAPI_URL}/api/terms?populate=*&locale=${searchLanguage}&filters[text][$startsWith]=${letter}&sort[0]=text%3Aasc`, requestOptions)
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/terms?fields[3]=free&populate[synonyms][fields][0]=text&populate[synonyms][fields][1]=definition&populate[localizations][populate][synonyms][fields][0]=text&populate[localizations][populate][synonyms][fields][1]=definition&populate[localizations][populate][synonyms][fields][2]=locale&populate[localizations][fields][0]=text&populate[localizations][fields][1]=definition&populate[localizations][fields][2]=locale&fields[0]=text&locale=${'de'}&fields[1]=definition&sort[0]=text%3Aasc&filters[text][$startsWith]=${letter}`, requestOptions)
        .then((response) => {
            if(response.status !== "200"){
                let err = Error;
                err.Message = "Invalid response code: " + response.status;
            }
            return response.json();
        })
        .then((json) => {
            setTermsMeta(json.meta)
            setTerms(json.data)
            setIsLoadingTerms(false)
            // setMeaning([])
            // props.history.push('/dictionary/0')
            if(json.data.length > 0 && searched !== '') {
                setCurrentLetter(json.data[0].attributes.text.charAt(0).toUpperCase())
                // setSearched('')

            } 
            else if(json.data.length > 0 && searched === '') {
                // if(currentSearch === "*") {
                //     return null
                // }
                setCurrentLetter(json.data[0].attributes.text.charAt(0).toUpperCase())
            }
            else if (json.data.length === 0) {
                setCurrentLetter('')
            }
            
            // setCurrentLetter(json.data[0].attributes.title.charAt(0))
            // console.log(json.data[0].attributes.title.charAt(0))
            // console.log(json.data.length > 0 )
        })
    }

    const getAllTerms = (lang) => {
        setIsLoadingTerms(true)
        setCurrentLetter("")
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/terms?fields%5B0%5D=id&fields%5B1%5D=text&fields%5B2%5D=definition&fields%5B3%5D=free&fields%5B4%5D=locale&populate%5Bsynonyms%5D%5Bfields%5D%5B0%5D=id&populate%5Bsynonyms%5D%5Bfields%5D%5B1%5D=text&populate%5Bsynonyms%5D%5Bfields%5D%5B2%5D=definition&populate%5Bsynonyms%5D%5Bfields%5D%5B3%5D=free&populate%5Bsynonyms%5D%5Bfields%5D%5B4%5D=locale&populate%5Blocalizations%5D%5Bfields%5D%5B0%5D=id&populate%5Blocalizations%5D%5Bfields%5D%5B1%5D=text&populate%5Blocalizations%5D%5Bfields%5D%5B2%5D=definition&populate%5Blocalizations%5D%5Bfields%5D%5B3%5D=free&populate%5Blocalizations%5D%5Bfields%5D%5B4%5D=locale&populate%5Blocalizations%5D%5Bpopulate%5D%5Bsynonyms%5D%5Bfields%5D%5B0%5D=id&populate%5Blocalizations%5D%5Bpopulate%5D%5Bsynonyms%5D%5Bfields%5D%5B1%5D=text&populate%5Blocalizations%5D%5Bpopulate%5D%5Bsynonyms%5D%5Bfields%5D%5B2%5D=definition&populate%5Blocalizations%5D%5Bpopulate%5D%5Bsynonyms%5D%5Bfields%5D%5B3%5D=free&populate%5Blocalizations%5D%5Bpopulate%5D%5Bsynonyms%5D%5Bfields%5D%5B4%5D=locale&sort%5B0%5D=text%3Aasc&filters%5Btext`, { 
                headers, method: 'GET' 
        })
        .then(data => data.json())
        .then(data => {setTerms(data.data); setIsLoadingTerms(false); setIsLoadingMeaning(false); setTermsMeta(data.meta)})
    }



    const getMeaningTraducion = (meaningTraductionId) => {
        const requestOptions = {
            method: 'GET', 
      
        }
        var start = window.performance.now();
        console.time("timer1");

        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/terms/${meaningTraductionId}?populate=*`, requestOptions)
        .then((response) => {
            if(response.status !== "200"){
                let err = Error;
                err.Message = "Invalid response code: " + response.status;
            }
            return response.json();
        })
        .then((json) => {
            setMeaningTranslation(json.data.attributes)
            setIsLoadingMeaningTranslation(false)
            console.timeEnd("timer1");
            var end = window.performance.now();
            var time = end - start;
            console.log(time)
            setTimeToFetch(time + 1000)
        })
    }

    const getTermsWithPredictive = (lan, search) => {
        setIsLoadingPredictedTerms(true)
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/terms?populate=[terms]&[fields][1]=free&[fields][2]=text&locale=${lan}&filters[text][$startsWith]=${search}&pagination[limit]=5&sort[0]=text%3Aasc`, { 
            headers, method: 'GET' 
        })
            .then(data => data.json())
            .then(( data ) => {
                // if(currentSearch === "") {
                //     console.log("EMPTY!!!")
                // }
                setPredictedTerms(data.data);
                setDisplayedPredicted(true); 
                setIsLoadingPredictedTerms(false)
            })
        
    }

    const sendConsultedTermMetric = (id) => {
        // setIsLoadingTerms(true)
        // setCurrentLetter("")
        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/mterm/${id}`, {method: 'POST' })
            // .then(data => data.json())
            // .then(data => {setTerms(data.data); setIsLoadingTerms(false); setIsLoadingMeaning(false); setTermsMeta(data.meta)})
    }

    let traductions = {...meaning.localizations}
    // console.log(meaning.localizations)
    // console.log(Object.values({...traductions}))
    let listOfTradcutions = []
    let listOfCurrentTraductions = []
    Object.values({...traductions}).map((l)=> {
                         
        // console.log(l.attributes.locale === 'fr')
        listOfTradcutions.push(
            l.id, l.locale
        );
        // console.log('listOfTradcutions')
        listOfCurrentTraductions = listOfTradcutions.slice(0).slice(-6)
 
        
    })

    const changeDisplayedLetters = () => {
        setDisplayedLetters(!displayedLetters)
    }


    function useOutsideAlerter(ref) {
        useEffect(() => {
         
           // Alert if clicked on outside of element
         
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target) ) {
                setDisplayedLetters(false)
                // setDisplayedSearchBarOptions(false)

            }
          
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
    }

    function useOutsideAlerter2(ref2) {
        useEffect(() => {
         
           // Alert if clicked on outside of element
         
          function handleClickOutside(event) {
            if (ref2.current && !ref2.current.contains(event.target) ) {
                // setDisplayedLetters(false)
                setDisplayedSearchBarOptions(false)

            }
          
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref2]);
    }

    const wrapperRef= useRef(null);
    
    useOutsideAlerter(wrapperRef);

    const wrapperRef2= useRef(null);

    useOutsideAlerter2(wrapperRef2);

    const FilterByLetter = ({currentLetters}) => {

        return (
            <>
            <div style={{fontFamily: 'Work Sans'}}>
                <div>
                    <div className={classes.filterByLetterMain}>
                        <div className={classes.moreLetters}>
                            <span>{currentLetters}</span>
                            <button onClick={() => changeDisplayedLetters()} className='none'><i className='icon ms-1 icon-more'></i></button>
                        </div>
                        <div className={classes.FilterByLetterButtonsContainer}>
                            <button 
                                style={{
                                    backgroundColor: ['A', 'D', 'G', 'J', 'M', 'P', 'S', 'V', 'Y', 'a', 'd', 'g', 'j', 'm', 'p', 's', 'v', 'y'].includes(currentLetter) && currentLetters.includes(currentLetter)  && currentSearch === currentLetter? 'rgba(243, 191, 76, .55)' : 'none',
                                    border: currentSearch !== currentLetter && currentSearch.charAt(0).toUpperCase() === currentLetter.toUpperCase() && ['A', 'D', 'G', 'J', 'M', 'P', 'S', 'V', 'Y', 'a', 'd', 'g', 'j', 'm', 'p', 's', 'v', 'y'].includes(currentLetter) ? '3px solid rgb(243, 191, 76, .55)' : ''
                                }} 
                                className={classes.filterButton}
                                id={Object.values(currentLetters)[0]} 
                                onClick={() => {
                                    getTermsWithLetter(Object.values(currentLetters)[0]); 
                                    setCurrentSearch(Object.values(currentLetters)[0]);
                                    setScrolledCount(2);
                                    setSearched('')
                                    setReloaded(false)
                                    setTermNotSelected(true)
                                    setMeaning([])
                                    props.history.push({
                                        pathname: "/openDictionary/0",
                                    })
                                }}
                            >
                                {Object.values(currentLetters)[0]}
                            </button>
                            <button 
                                style={{
                                    backgroundColor: ['B', 'E', 'H', 'K', 'N', 'Q', 'T', 'W', 'Z', 'b', 'e', 'h', 'k', 'n', 'q', 't', 'w', 'z'].includes(currentLetter) && currentLetters.includes(currentLetter) && currentSearch === currentLetter? 'rgba(243, 191, 76, .55)' : 'none',
                                    border: currentSearch !== currentLetter && currentSearch.charAt(0).toUpperCase() === currentLetter.toUpperCase() && ['B', 'E', 'H', 'K', 'N', 'Q', 'T', 'W', 'Z', 'b', 'e', 'h', 'k', 'n', 'q', 't', 'w', 'z'].includes(currentLetter) ? '3px solid rgb(243, 191, 76, .55)' : ''
                                }}  
                                className={classes.filterButton}
                                id={Object.values(currentLetters)[1]} 
                                onClick={() => {
                                    getTermsWithLetter(Object.values(currentLetters)[1]); 
                                    setCurrentSearch(Object.values(currentLetters)[1]);
                                    setScrolledCount(2);
                                    setSearched('')
                                    setReloaded(false)
                                    setTermNotSelected(true)
                                    setMeaning([])
                                    props.history.push({
                                        pathname: "/openDictionary/0",
                                    })
                                }}
                            >
                                {Object.values(currentLetters)[1]}
                            </button>
                            <button 
                                style={{
                                    backgroundColor: ['C', 'F', 'I', 'L', 'O', 'R', 'U', 'X', 'c', 'f', 'i', 'l', 'o', 'r', 'u', 'x'].includes(currentLetter) && currentLetters.includes(currentLetter) && currentSearch === currentLetter ? 'rgba(243, 191, 76, .55)' : 'none',
                                    border: currentSearch !== currentLetter && currentSearch.charAt(0).toUpperCase() === currentLetter && ['C', 'F', 'I', 'L', 'O', 'R', 'U', 'X', 'c', 'f', 'i', 'l', 'o', 'r', 'u', 'x'].includes(currentLetter) ? '3px solid rgb(243, 191, 76, .55)' : ''
                                }}  
                                className={classes.filterButton}
                                id={Object.values(currentLetters)[2]} 
                                onClick={() => {
                                    getTermsWithLetter(Object.values(currentLetters)[2]); 
                                    setCurrentSearch(Object.values(currentLetters)[2]);
                                    setScrolledCount(2)
                                    setSearched('')
                                    setReloaded(false)
                                    setTermNotSelected(true)
                                    setMeaning([])
                                    props.history.push({
                                        pathname: "/openDictionary/0",
                                    })
                                }}
                            >
                                {Object.values(currentLetters)[2]}
                            </button>
                            <button 
                                style={{
                                    backgroundColor: currentSearch === "" && currentLetter === "" ? 'rgba(243, 191, 76, .55)' : 'none', fontSize:'small'
                                }}  
                                className={classes.filterButton}
                                id={"allTerms"} 
                                onClick={() => {
                                    getAllTerms(searchLanguage)
                                    setCurrentSearch("");
                                    setScrolledCount(2);
                                    setSearched('')
                                    setReloaded(false)
                                    setTermNotSelected(true)
                                    // setMeaning([])
                                    setCurrentLetters(["A", "B", "C"])
                                    props.history.push({
                                        pathname: "/openDictionary/0",
                                    })
                                }}
                            >
                                
                                {/* {
                                    props.language === "de"
                                    ?
                                    <>alle Begriffe</>
                                    :
                                    <>all terms</>
                                }  */}
                                {pageContent.search_all_button}
                            </button>
                        </div>
                        
                    </div>
                    {displayedLetters
                        ?
                        <span className={classes.radioSelectLettersContainer} ref={wrapperRef} >
                            <div className={classes.radioSelectLettersTitle} >{pageContent.radio_button_filter}</div>
                            <div className={classes.radioSelectLetters} >
                                <div className={classes.radioSelectLettersColumn}>
                                    <div>
                                        <RadioInput 
                                            value={['A','B','C']}
                                            selected={selected}
                                            text="ABC"
                                            onChange={(val) => {
                                                setSelected(val); 
                                                setCurrentLetters(val); 
                                                setCurrentLetter(val[0]); 
                                                getTermsWithLetter(val[0]); 
                                                setDisplayedLetters(false);
                                                setCurrentSearch(val[0]);
                                                setScrolledCount(2);
                                                props.history.push({
                                                    pathname: "/openDictionary/0",
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <RadioInput 
                                            value={['D','E','F']}
                                            selected={selected}
                                            text="DEF"
                                            onChange={(val) => {
                                                setSelected(val); 
                                                setCurrentLetters(val); 
                                                setCurrentLetter(val[0]); 
                                                getTermsWithLetter(val[0]); 
                                                setDisplayedLetters(false);
                                                setCurrentSearch(val[0]);
                                                setScrolledCount(2)
                                                props.history.push({
                                                    pathname: "/openDictionary/0",
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <RadioInput 
                                            value={['G','H','I']}
                                            selected={selected}
                                            text="GHI"
                                            onChange={(val) => {
                                                setSelected(val); 
                                                setCurrentLetters(val); 
                                                setCurrentLetter(val[0]); 
                                                getTermsWithLetter(val[0]); 
                                                setDisplayedLetters(false);
                                                setCurrentSearch(val[0]);
                                                setScrolledCount(2)
                                                props.history.push({
                                                    pathname: "/openDictionary/0",
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <RadioInput 
                                            value={['J','K','L']}
                                            selected={selected}
                                            text="JKL"
                                            onChange={(val) => {
                                                setSelected(val); 
                                                setCurrentLetters(val); 
                                                setCurrentLetter(val[0]); 
                                                getTermsWithLetter(val[0]);  
                                                setDisplayedLetters(false);
                                                setCurrentSearch(val[0]);
                                                setScrolledCount(2)
                                                props.history.push({
                                                    pathname: "/openDictionary/0",
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <RadioInput 
                                            value={['M','N','O']}
                                            selected={selected}
                                            text="MNO"
                                            onChange={(val) => {
                                                setSelected(val); 
                                                setCurrentLetters(val); 
                                                setCurrentLetter(val[0]); 
                                                getTermsWithLetter(val[0]); 
                                                setDisplayedLetters(false);
                                                setCurrentSearch(val[0]);
                                                setScrolledCount(2)
                                                props.history.push({
                                                    pathname: "/openDictionary/0",
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.radioSelectLettersColumn}>
                                    <div>
                                        <RadioInput 
                                            value={['P','Q','R']}
                                            selected={selected}
                                            text="PQR"
                                            onChange={(val) => {
                                                setSelected(val); 
                                                setCurrentLetters(val); 
                                                setCurrentLetter(val[0]); 
                                                getTermsWithLetter(val[0]);  
                                                setDisplayedLetters(false);
                                                setCurrentSearch(val[0]);
                                                setScrolledCount(2)
                                                props.history.push({
                                                    pathname: "/openDictionary/0",
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <RadioInput 
                                            value={['S','T','U']}
                                            selected={selected}
                                            text="STU"
                                            onChange={(val) => {
                                                setSelected(val); 
                                                setCurrentLetters(val); 
                                                setCurrentLetter(val[0]); 
                                                getTermsWithLetter(val[0]);  
                                                setDisplayedLetters(false);
                                                setCurrentSearch(val[0]);
                                                setScrolledCount(2)
                                                props.history.push({
                                                    pathname: "/openDictionary/0",
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <RadioInput 
                                            value={['V','W','X']}
                                            selected={selected}
                                            text="VWX"
                                            onChange={(val) => {
                                                setSelected(val); 
                                                setCurrentLetters(val); 
                                                setCurrentLetter(val[0]); 
                                                getTermsWithLetter(val[0]); 
                                                setDisplayedLetters(false);
                                                setCurrentSearch(val[0]);
                                                setScrolledCount(2)
                                                props.history.push({
                                                    pathname: "/openDictionary/0",
                                                })
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <RadioInput 
                                            value={['Y','Z']}
                                            selected={selected}
                                            text="YZ"
                                            onChange={(val) => {
                                                setSelected(val); 
                                                setCurrentLetters(val); 
                                                setCurrentLetter(val[0]); 
                                                getTermsWithLetter(val[0]);  
                                                setDisplayedLetters(false);
                                                setCurrentSearch(val[0]);
                                                setScrolledCount(2)
                                                props.history.push({
                                                    pathname: "/openDictionary/0",
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </span>
                        :
                        null                   
                    }
                </div>
            </div>
            </>
        )
    }

    let history = useHistory()

    const handleShareButton = (ShareId, ShareTitle) => {
        // Check if navigator.share is supported by the browser
        if (navigator.share) {
          console.log("Congrats! Your browser supports Web Share API");
          navigator
            .share({
              url: `https://accounting-a-z.ch/dictionary/${ShareId}`,
              title: '*Accounting dictionary* - Register! More than 1,000 terms',
              text: `Accounting dictionary - Register! More than 1,000 terms \n ${ShareTitle}`,
            })
            .then(() => {
              console.log("Sharing successfull");
            })
            .catch(() => {
              console.log("Sharing failed");
            });
        } else {
          console.log("Sorry! Your browser does not support Web Share API");
        }
    };

    
    return(
        <div className={classes.container}>
            {
                window.onbeforeunload = function () {
                    fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/removeactiveuser/${window.localStorage.getItem("id")}`, { 
                        method: 'GET', keepalive: true
                    })
                    fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/timesave/${(entry.time/1000)/60}`, { 
                        method: 'GET', keepalive: true
                    })
                }
            }
           
            <div ref={ref}  style={{display:'none'}}>none</div>
            <Toast
                toastList={toastProperties}
                position="top-right" 
                visible={toastVisible}
                pageContent={pageContent}
            />
            <AppHeader  
                setDictionaryContent={(val) => setPageContent(val)}
                appLanguage={props.language}
                openDictionary = {true}
                currentTerm = {meaning.text}
                // setCurrentTerm={(val) => console.log(val)}
                onSelectPreviosSearch={(val) => setDisplayedSearchBarOptions(val)}
                searchBarDisplayed={searchBarDisplayed}
                setSearchBarDisplayed={(val) => setSearchBarDisplayed(val)}
                currentPathName={props.location.pathname}
                currentId={props.location.pathname.replace('/dictionary/','')}
                onClick3={(letters) => getTermsWithLetter(letters) }
                onClick5={(search) => setSearched(search)}
                onFocus1={() => setDisplayedSearchBarOptions(true)}
                // onBlur1={() => setDisplayedSearchBarOptions(false)}
                setReloaded={(val) => setReloaded(val)}
                currentSearch={currentSearch}
                isLoadingPredictedTerms={isLoadingPredictedTerms}
                getTermsWithPredictive={(partial) => {
                    setDisplayedSearchBarOptions(true)
                    if(partial ===""){
                        setDisplayedPredicted(false)
                        setPredictedTerms([])
                    } else {
                        // console.log(partial===currentSearch)
                        getTermsWithPredictive(searchLanguage ,partial);
                        // console.log(partial)
                    }
                    
                }}
                setCurrentSearch={(val) => {
                    setCurrentSearch(val); 
                    if(val === "") {
                        console.log("EMPTY!!!")
                        setPredictedTerms([])
                    }
                }}
                displayedPredicted={displayedPredicted}
                predictedTerms = {predictedTerms}
                displayedSearchBarOptions={displayedSearchBarOptions}
                sendConsultedTermMetric={(id) => sendConsultedTermMetric(id)}
                ref2={wrapperRef2}
                options={displayedSearchBarOptions}
                search={props.match.path}
                onChange1={(lan) => {
                    setSearchLanguage(lan);
                    console.log(listOfCurrentTraductions); 
                    let translationId = listOfCurrentTraductions[listOfCurrentTraductions.indexOf(lan)-1];  
                    if(translationId === undefined) {
                        var currentId = props.match.params.id
                    } else {
                        currentId = translationId
                    }; 
                    console.log(currentId);
                    if(currentId !== 0){
                        getMeaning(currentId)
                    }
                    
                    
                    history.push(`/dictionary/${currentId}`)
                    getTermsWithLanguage(lan)
                    
                   
                }}
                lastVisited = {localStorage.getItem('recentSearch') !== null ? localStorage.getItem('recentSearch').split(/\,\/|\,(?=[a-zA-Z])/g).concat(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']): ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']}
                getMeaning={(id) => getMeaning(id)}
                // setMobileMeaningStyle = {(val) => setMobileMeaningStyle(val)}
                // mobileMeaningStyle = {mobileMeaningStyle}
                mobile = {mobile}
                setLink = {(val) => setLink([...[val], ...link])}
                language={searchLanguage}
                setTermVisible={(val) => {
                    setSearched(val); 
                    getTermsWithLetter(val)
                }}
                setCurrentLetters={(val) => setCurrentLetters(val)}
                termNotSelected={termNotSelected}
                setTermNotSelected={(f) => setTermNotSelected(f)}

            />
            <div id='dictionary' className={classes.dictionary}>
                <div className={classes.dictionaryContainer}>
                    <div  className={classes.termsContainer} style={{display: mobileMeaningStyle ? 'none' : 'block'}} >
                        <div className={classes.searchParams}>
                            <div className={classes.searchParamsInfo}>
                                {(() => {
                                    if (currentLetter !== "" && searched === '') {
                                    return (
                                        <h3 style={{margin: '0'}}>
                                            {/* {
                                                props.language === "de"
                                                ?
                                                <>Begriffe beginnend mit <span style={{fontStyle:'italic'}}>{currentSearch}</span></>
                                                :
                                                <>Terms starting with <span style={{fontStyle:'italic'}}>{currentSearch}</span></>
                                            }  */}
                                            {pageContent.search_title_specific} <span style={{fontStyle:'italic'}}>{currentSearch}</span>
                                        </h3>
                                    )
                                    } else if (currentLetter === "" && searched === '') {
                                    return (
                                        <h3 style={{margin: '0'}}>
                                       
                                            {/* {
                                                props.language === "de"
                                                ?
                                                <>Alle Begriffe</>
                                                :
                                                <>All terms</>
                                            } */}
                                            {pageContent.search_title_all}
                                        </h3>
                                    )
                                    } else if (searched !== '') {
                                    return (
                                        <h3 style={{margin: '0'}}>
                                            {/* {
                                                props.language === "de"
                                                ?
                                                <>Begriffe beginnend mit <span style={{fontStyle:'italic'}}>{searched}</span></>
                                                :
                                                <>Terms starting with <span style={{fontStyle:'italic'}}>{searched}</span></>
                                            } */}
                                            {pageContent.search_title_specific} <span style={{fontStyle:'italic'}}>{searched}</span>
                                        </h3>
                                    )
                                    }
                                })()}
                                <div style={{color:'#A5A5A5', fontFamily:"'Roboto'"}}> 
                                    <span className={classes.numberOfTerms}>
                                    
                                    {/* {
                                        props.language === "de"
                                        ?
                                        <>Anzahl der Begriffe:</>
                                        :
                                        <>Number of terms:</>
                                    } */}
                                    {pageContent.number_of_terms}:
                                    </span>  {terms.length}/{{...{...termsMeta}.pagination}.total}
                                </div>
                            </div>
                            <FilterByLetter currentLetters={currentLetters}/>
                        </div>
                        {
                            isLoadingTerms
                            ?
                            <>
                            <div className={classes.termSkeleton}>
                            <SkeletonTheme
                                baseColor="#E1E2E1"
                                highlightColor="#FDFDFD"
                                borderRadius="10"
                                duration={2}
                            >
                                {[1,2,3,4,5].map(() => (
                                    <div className={classes.termBox}>
                                        <Skeleton width={100} />
                                        <div className=''>
                                            <div>
                                                <Skeleton highlightColor="#FDFDFD" width={100} />
                                            </div>
                                        </div>
                                        <div className=''>
                                            <div><Skeleton highlightColor="#FDFDFD" width={100} /></div>
                                        </div>
                                        <div className=''>
                                            <div><Skeleton highlightColor="#FDFDFD" width={100} /></div>
                                        </div>
                                    </div>
                                ))}
                                
                                
                            </SkeletonTheme>
                            </div>
                            </>
                            :
                            <>
                  
                            <Terms 
                                pageContent={pageContent}
                                openDictionary={true}
                                termNotSelected={termNotSelected}
                                setTermNotSelected={(f) => setTermNotSelected(f)}
                                currentSearch={currentSearch}
                                scroll={scrolledCount}
                                scrolledCount={(n) => {
                                    setScrolledCount((prevState) => { 
                                        // console.log(typeof(prevState) )
                                         if (prevState <= termsMeta.pagination.pageCount) {
                                            return n + prevState
                                        } else {
                                            return prevState
                                        }
                                    })
                                    
                                }}
                                expandCurrentSearch={(exp) => {
                                    
                                    setTerms((prevState) => {
                                        if (scrolledCount <= termsMeta.pagination.pageCount) {
                                            return ([
                                                ...prevState,
                                                ...exp
                                                ])
                                        } else {
                                            return prevState
                                        }
                                        
                                    })
                                }
                                    
                                }
                                reloaded={reloaded}
                                loaderLimit={scrolledCount}
                                loading={loader}
                                setLoader={(v) => setLoader(v)}
                                termsMeta={termsMeta}
                                termNotFound={terms.length === 0}
                                isLoading={isLoadingTerms}
                                terms={terms}
                                // meaningHasSynonym={meaningHasSynonym}
                                onClick2={(id) => {getMeaning(id); window.localStorage.setItem("termId", id);}} 
                                currentTerm = {meaning.text}
                                currentTermId = {termId}
                                clipboard = {clipboard}
                                toastVisible = {toastVisible}
                                setClipboard = {(cli) => 
                                    {   setClipboard(cli); 
                                        setToastProperties({
                                            description: `${clipboard.toUpperCase()}`,
                                            borderColor: '#0A9DE4',
                                            icon: 'icon-success'
                                        })
                                    }
                                }
                                setToastVisible = {(val) => setToastVisible(val)}
                                setClipboardTitle = {(val) => setClipboardTitle(val)}
                                setLink = {(val) => {setLink([...[val], ...link]); localStorage.setItem('recentSearch',[...[val], ...link])}}
                                setShareId = {(val1, val2) => {handleShareButton(val1, val2)}}
                                setMobileMeaningStyle = {(val) => mobile ? setMobileMeaningStyle(val) : setMobileMeaningStyle(false)}
                                // setMobileMeaningStyle = {(val) => setMobileMeaningStyle(val)}
                                mobileMeaningStyle = {mobileMeaningStyle}
                                mobile = {mobile}
                                setSkeletonWidth = {(val) => setSkeletonWidth(val)}
                                searchLanguage = {searchLanguage}
                                sendConsultedTermMetric={(id) => sendConsultedTermMetric(id)}
                                newFilterSearch={newFilterSearch}
                                timeToFetch = {timeToFetch}

                            />
                            </>
                        }             
                        
                    </div>
                    <div style={{display: mobileMeaningStyle === true || mobile === false ? 'block' : 'none'}} className={classes.MeaningContainer}>
                        {
                            isLoadingMeaning
                            ?
                            <>
                                <SkeletonTheme
                                baseColor="#E1E2E1"
                                highlightColor="#FDFDFD"
                                borderRadius="10"
                                duration={2}
                            >
                                <div className={classes.meaningSkeleton}>
                                    <div className={classes.meaningSkeletonTitle} style={{marginBottom: mobile?'30px':'50px'}}>
                                        <h3><Skeleton width={100} /></h3>
                                    </div>
                                    <div className={classes.meaningSkeletonText}>
                                        <div>
                                            <Skeleton  />
                                        </div>
                                        <div>
                                            <Skeleton  count={11} />
                                        </div>
                                    </div>
                                </div>
                            </SkeletonTheme>
                                
                            </>
                            :
                            <div style={{display:'flex', backgroundColor:'white'}}>
                            <Meaning 
                                pageContent={pageContent}
                                language={props.language}
                                openDictionary={true}
                                termNotSelected={termNotSelected}
                                meaning={meaning}
                                meaningHasSynonym={meaningHasSynonym}
                                id = {termId}
                                listOfCurrentTraductions = {listOfCurrentTraductions}
                                meaningTranslation = {meaningTranslation}
                                onClick4 = {(meaningTraductionId) => getMeaningTraducion(meaningTraductionId)}
                                searchLanguage = {searchLanguage}
                                isLoadingMeaningTranslation = {isLoadingMeaningTranslation}
                                setIsLoadingMeaningTranslation = {(val) => setIsLoadingMeaningTranslation(val)}
                                mobile = {mobile}
                                setMobileMeaningStyle = {(val) => setMobileMeaningStyle(val)}
                                mobileMeaningStyle = {mobileMeaningStyle}
                                setShareId = {(val1, val2) => {handleShareButton(val1, null)}}
                                skeletonWidth = {skeletonWidth}
                                setSkeletonWidth = {(val) => setSkeletonWidth(val)}
                                timeToFetch = {timeToFetch}
                            />
                            {
                                mobile
                                ?
                                <></>
                                :
                                // <AdComponentVertical/>
                                <></>
                            }
                            
                            </div>
                           
                        }
                         {/* <AdsComponent/>  */}
                    </div>
                    
                </div>
            </div>
            <AppFooter language={props.language} />
        </div>
    );

}