import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import classes from './WebFooter.module.css'

export default function WebFooter({language}) {

    function createMarkup(str) {
        return {__html: str};
    }

    const [componentContent, setComponentContent] = useState({})

    useEffect(()=>{
        document.getElementById("year").innerHTML = new Date().getFullYear()

        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/footer/?locale=${language}`, { 
            'Content-Type': 'application/json' , method: 'GET' 
            })
            .then(data => data.json())
            .then(data => setComponentContent(data.data.attributes))
    }, [language])

    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return(
        <>
        <footer>
                <div className={classes.aboutContainer}>
                    <div className={classes.about}>
                        <div className={classes.aboutContent}>
                            <div className={classes.aboutWebpage}>
                                {/* <div className=""> */}
                                    <div>
                                        <i className={`${classes.iconFooter} icon-home`}></i>
                                        <Link
                                            to={`/`}
                                            className={classes.footerLink}
                                            onClick={() => {topFunction()}}
                                        >
                                            {componentContent.home_title}
                                        </Link>
                                    </div>
                                    <div>
                                        <i className={`${classes.iconFooter} icon-app`}></i>
                                        <Link
                                            to={`/abouttheapp`}
                                            className={classes.footerLink}
                                            onClick={() => {topFunction()}}
                                        >
                                            {componentContent.about_the_app_title}
                                        </Link>
                                    </div>
                                    <div>
                                        <i className={`${classes.iconFooter} icon-book`}></i>
                                        <Link
                                            to={`/aboutthebook`}
                                            className={classes.footerLink}
                                            onClick={() => {topFunction()}}
                                        >
                                            {componentContent.about_the_book_title}
                                        </Link>
                                    </div>
                                    <div>
                                        <i className={`${classes.iconFooter} icon-author`}></i>
                                        <Link
                                            to={`/abouttheauthor`}
                                            className={classes.footerLink}
                                            onClick={() => {topFunction()}}
                                        >
                                            {componentContent.about_the_author_title}
                                        </Link>
                                    </div>
                                    <div>
                                        <i className={`${classes.iconFooter} icon-register`}></i>
                                        <Link
                                            to={`/registration`}
                                            className={classes.footerLink}
                                            onClick={() => {topFunction()}}
                                        >
                                            {componentContent.register_link}
                                        </Link>
                                    </div>
                                {/* </div> */}
                            </div>
                            <div className={classes.relatedInfoContainer}>
                                {/* <div > */}
                                    {/* <div className={classes.relatedSocialMedia}>
                                        <i className="icon-socialM icon-facebook me-2"></i>
                                        <i className="icon-socialM icon-instagram me-2"></i>
                                        <i className="icon-socialM icon-twitter me-2"></i>
                                    </div> */}
                                {/* </div> */}
                                <div className={classes.relatedAdress}>
                                    <div dangerouslySetInnerHTML={createMarkup(componentContent.address_text)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.imprintContainer}>
                    <div className={classes.imprint}>
                        <div style={{margin:'0 10px'}}>
                            <Link className={classes.imprintLink} onClick={() => {topFunction()}} to={`/termsOfUse`}> {componentContent.terms_of_use_title} </Link> <span style={{color:'white', margin:'0 15px'}}>|</span> 
                            <Link className={classes.imprintLink} onClick={() => {topFunction()}} to={`/privacyNotice`}> {componentContent.privacy_policy_title} </Link> <span style={{color:'white', margin:'0 15px'}}>| </span>
                            <Link className={classes.imprintLink} onClick={() => {topFunction()}} to={`/imprint`}>{componentContent.imprint_title}</Link>
                        </div>
                        <p className={classes.imprintCopyright}>{componentContent.copyright_text} <span id="year"></span></p>
                    </div>
                </div>
        </footer>
        </>
    )
}
