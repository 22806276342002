import React from "react";

export default function SvgPatternBackground(props) {
    return (
        <>
       <svg  width={'100%'} height={`${props.height}`} style={{position:'absolute', opacity:'1', borderRadius: `${props.borderRadius}`, zIndex:'0', backgroundColor: `${props.backgroundColor}`}}>
            <pattern style={{transform: `rotate(${props.angle}deg)`}} id={`pattern-circles${props.number}`} width={`${443.52/props.scale}`} height={`${456.59/props.scale}`}  viewBox="0 0 443.52 456.59" patternUnits="userSpaceOnUse">
                <g style={{fill:`#${props.color}`}} > 
                    <polygon points="309.2 211.33 336.96 211.33 346.26 203.04 309.2 203.04 309.2 211.33"  />
                    <polygon points="361.08 189.83 368.84 182.91 368.84 180.79 359.54 172.49 359.54 189.83 361.08 189.83"  />
                    <polygon points="343.31 186.04 343.31 158.03 334.01 149.73 334.01 186.04 343.31 186.04"  />
                    <polygon points="317.78 211.35 317.78 135.27 308.48 126.97 308.48 211.35 317.78 211.35"  />
                    <polygon points="439.6 41.16 439.6 3.01 430.3 3.5 430.3 41.16 439.6 41.16"  />
                    <rect x="311.13" y="7.5" width="31.92" height="8.29"  />
                    <rect x="385.27" y="180.59" width="31.92" height="8.29"  />
                    <polygon points="410.6 5.21 410.6 145.69 419.9 137.39 419.9 5.08 410.6 5.21"  />
                    <polygon points="359.54 7.74 359.54 172.49 368.84 180.79 368.84 7.74 359.54 7.74"  />
                    <polygon points="334.01 7.74 334.01 149.73 343.31 158.03 343.31 7.74 334.01 7.74"  />
                    <polygon points="385.07 137.52 394.37 137.52 395.29 7.17 385.99 7.17 385.07 137.52"  />
                    <polygon points="385.07 145.82 385.07 168.45 394.37 160.15 394.37 145.82 385.07 145.82"  />
                    <rect x="308.48" y="7.58" width="9.3" height="61.95"  />
                    <polygon points="308.48 77.82 308.48 126.97 317.78 135.27 317.78 77.82 308.48 77.82"  />
                    <polygon points="255.9 72.72 255.9 72.72 255.9 72.88 255.9 72.72"  />
                    <polygon points="336.96 211.33 377.54 211.33 384.03 203.37 346.26 203.04 336.96 211.33"  />
                    <polygon points="335.02 260.86 342.6 250.91 324.77 250.85 383.3 211.02 384.03 203.37 352.31 203.09 350.78 211.33 366.82 211.33 307.85 251.02 306.33 260.76 335.02 260.86"  />
                    <polyline points="413.5 260.65 413.5 252.35 339.96 250.85 332.39 260.76"  />
                    <polygon points="340.66 272.98 308.75 273.45 308.9 281.74 340.81 281.27 340.66 272.98"  />
                    <polygon points="431.38 50.42 431.38 380.83 440.69 372.53 440.69 50.42 431.38 50.42"  />
                    <polygon points="408.03 252.33 408.03 411 417.34 402.71 417.34 252.33 408.03 252.33"  />
                    <polygon points="356.97 273.06 356.97 452.91 366.27 453.15 366.27 273.06 356.97 273.06"  />
                    <polygon points="331.44 273.05 331.44 451.04 340.74 451.19 340.74 273.05 331.44 273.05"  />
                    <rect x="382.5" y="275.82" width="9.3" height="127.02"  />
                    <polygon points="382.5 411.13 382.5 433.76 391.8 425.47 391.8 411.13 382.5 411.13"  />
                    <rect x="305.88" y="273.3" width="9.3" height="61.54"  />
                    <polygon points="394.25 238.33 403.55 238.33 404.35 201.75 395.05 201.75 394.25 238.33"  />
                    <polygon points="410.88 238.33 420.18 238.33 420.98 201.75 411.68 201.75 410.88 238.33"  />
                    <polygon points="305.91 343.13 305.91 450.47 315.21 450.65 315.21 343.13 305.91 343.13"  />
                    <polygon points="263.42 334.24 263.42 334.24 263.42 334.4 263.42 334.24"  />
                    <polygon points="368.84 189.83 368.84 182.91 361.08 189.83 368.84 189.83"  />
                    <rect x="305.9" y="333.89" width="31.92" height="9.37"  />
                    <polygon points="307.75 233.4 383.49 236.16 384.12 227.43 308.19 224.63 307.75 233.4"  />
                    <polygon points="419.9 188.82 419.9 137.39 410.6 145.69 410.6 188.82 419.9 188.82"  />
                    <polygon points="394.37 188.81 394.37 160.15 385.07 168.45 385.07 188.81 394.37 188.81"  />
                    <rect x="382.49" y="401.97" width="31.92" height="9.37"  />
                    <polygon points="430.82 432.85 430.82 453.95 440.12 454.07 440.12 432.85 430.82 432.85"  />
                    <rect x="382.7" y="445.9" width="31.92" height="8.29"  />
                    <polygon points="440.55 423.11 440.55 372.66 431.24 380.95 431.24 423.11 440.55 423.11"  />
                    <polygon points="417.34 454.2 417.34 402.71 408.03 411 408.03 454.2 417.34 454.2"  />
                    <polygon points="391.8 454.12 391.8 425.47 382.5 433.76 382.5 454.12 391.8 454.12"  />
                    <rect x="308.47" y="68.9" width="31.92" height="9.37"  />
                    <rect x="385.07" y="137.18" width="31.92" height="9.37"  />
                    <polygon points="159.82 211.33 187.58 211.33 196.89 203.04 159.82 203.04 159.82 211.33"  />
                    <polygon points="211.7 189.83 219.46 182.91 219.46 180.79 210.16 172.49 210.16 189.83 211.7 189.83"  />
                    <polygon points="193.93 186.04 193.93 158.03 184.63 149.73 184.63 186.04 193.93 186.04"  />
                    <polygon points="168.4 211.35 168.4 135.27 159.1 126.97 159.1 211.35 168.4 211.35"  />
                    <polygon points="290.23 41.16 290.23 3.01 280.92 3.5 280.92 41.16 290.23 41.16"  />
                    <rect x="161.75" y="7.5" width="31.92" height="8.29"  />
                    <rect x="235.89" y="180.59" width="31.92" height="8.29"  />
                    <polygon points="261.22 5.21 261.22 145.69 270.53 137.39 270.53 5.08 261.22 5.21"  />
                    <polygon points="210.16 7.74 210.16 172.49 219.46 180.79 219.46 7.74 210.16 7.74"  />
                    <polygon points="184.63 7.74 184.63 149.73 193.93 158.03 193.93 7.74 184.63 7.74"  />
                    <polygon points="235.69 137.52 245 137.52 245.91 7.17 236.61 7.17 235.69 137.52"  />
                    <polygon points="235.69 145.82 235.69 168.45 245 160.15 245 145.82 235.69 145.82"  />
                    <rect x="159.1" y="7.58" width="9.3" height="61.95"  />
                    <polygon points="159.1 77.82 159.1 126.97 168.4 135.27 168.4 77.82 159.1 77.82"  />
                    <polygon points="106.52 72.72 106.52 72.72 106.52 72.88 106.52 72.72"  />
                    <polygon points="187.58 211.33 228.17 211.33 234.65 203.37 196.89 203.04 187.58 211.33"  />
                    <polygon points="185.65 260.86 193.23 250.91 175.4 250.85 233.93 211.02 234.65 203.37 202.93 203.09 201.4 211.33 217.45 211.33 158.48 251.02 156.96 260.76 185.65 260.86"  />
                    <polyline points="264.13 260.65 264.13 252.35 190.59 250.85 183.01 260.76"  />
                    <polygon points="191.28 272.98 159.37 273.45 159.52 281.74 191.44 281.27 191.28 272.98"  />
                    <polygon points="282.01 50.42 282.01 380.83 291.31 372.53 291.31 50.42 282.01 50.42"  />
                    <polygon points="258.66 252.33 258.66 411 267.96 402.71 267.96 252.33 258.66 252.33"  />
                    <polygon points="207.59 273.06 207.59 452.91 216.9 453.15 216.9 273.06 207.59 273.06"  />
                    <polygon points="182.06 273.05 182.06 451.04 191.36 451.19 191.36 273.05 182.06 273.05"  />
                    <rect x="233.12" y="275.82" width="9.3" height="127.02"  />
                    <polygon points="233.12 411.13 233.12 433.76 242.43 425.47 242.43 411.13 233.12 411.13"  />
                    <rect x="156.51" y="273.3" width="9.3" height="61.54"  />
                    <polygon points="244.88 238.33 254.18 238.33 254.98 201.75 245.67 201.75 244.88 238.33"  />
                    <polygon points="261.5 238.33 270.81 238.33 271.61 201.75 262.3 201.75 261.5 238.33"  />
                    <polygon points="156.53 343.13 156.53 450.47 165.83 450.65 165.83 343.13 156.53 343.13"  />
                    <polygon points="114.04 334.24 114.04 334.24 114.04 334.4 114.04 334.24"  />
                    <polygon points="219.46 189.83 219.46 182.91 211.7 189.83 219.46 189.83"  />
                    <rect x="156.52" y="333.89" width="31.92" height="9.37"  />
                    <polygon points="158.37 233.4 234.11 236.16 234.74 227.43 158.81 224.63 158.37 233.4"  />
                    <polygon points="270.53 188.82 270.53 137.39 261.22 145.69 261.22 188.82 270.53 188.82"  />
                    <polygon points="245 188.81 245 160.15 235.69 168.45 235.69 188.81 245 188.81"  />
                    <rect x="233.11" y="401.97" width="31.92" height="9.37"  />
                    <polygon points="281.44 432.85 281.44 453.95 290.75 454.07 290.75 432.85 281.44 432.85"  />
                    <rect x="233.32" y="445.9" width="31.92" height="8.29"  />
                    <polygon points="291.17 423.11 291.17 372.66 281.87 380.95 281.87 423.11 291.17 423.11"  />
                    <polygon points="267.96 454.2 267.96 402.71 258.66 411 258.66 454.2 267.96 454.2"  />
                    <polygon points="242.43 454.12 242.43 425.47 233.12 433.76 233.12 454.12 242.43 454.12"  />
                    <rect x="159.09" y="68.9" width="31.92" height="9.37"  />
                    <rect x="235.69" y="137.18" width="31.92" height="9.37"  />
                    <polygon points="9.07 211.69 36.83 211.69 46.14 203.39 9.07 203.39 9.07 211.69"  />
                    <polygon points="60.95 190.19 68.72 183.27 68.72 181.14 59.41 172.85 59.41 190.19 60.95 190.19"  />
                    <polygon points="43.18 186.4 43.18 158.38 33.88 150.09 33.88 186.4 43.18 186.4"  />
                    <polygon points="17.65 211.71 17.65 135.62 8.35 127.33 8.35 211.71 17.65 211.71"  />
                    <polygon points="139.48 41.52 139.48 3.36 130.17 3.86 130.17 41.52 139.48 41.52"  />
                    <rect x="11" y="7.86" width="31.92" height="8.29"  />
                    <rect x="85.14" y="180.94" width="31.92" height="8.29"  />
                    <polygon points="110.48 5.57 110.48 146.04 119.78 137.75 119.78 5.44 110.48 5.57"  />
                    <polygon points="59.41 8.1 59.41 172.85 68.72 181.14 68.72 8.1 59.41 8.1"  />
                    <polygon points="33.88 8.09 33.88 150.09 43.18 158.38 43.18 8.09 33.88 8.09"  />
                    <polygon points="84.94 137.88 94.25 137.88 95.16 7.53 85.86 7.53 84.94 137.88"  />
                    <polygon points="84.94 146.17 84.94 168.8 94.25 160.51 94.25 146.17 84.94 146.17"  />
                    <rect x="8.35" y="7.93" width="9.3" height="61.95"  />
                    <polygon points="8.35 78.18 8.35 127.33 17.65 135.62 17.65 78.18 8.35 78.18"  />
                    <polygon points="36.83 211.69 77.42 211.69 83.9 203.72 46.14 203.39 36.83 211.69"  />
                    <polygon points="34.9 261.22 42.48 251.26 24.65 251.2 83.18 211.37 83.9 203.72 52.18 203.45 50.66 211.69 66.7 211.69 7.73 251.37 6.21 261.11 34.9 261.22"  />
                    <polyline points="113.38 261 113.38 252.71 39.84 251.21 32.26 261.11"  />
                    <polygon points="40.54 273.34 8.62 273.8 8.77 282.09 40.69 281.63 40.54 273.34"  />
                    <polygon points="131.26 50.78 131.26 381.18 140.56 372.89 140.56 50.78 131.26 50.78"  />
                    <polygon points="107.91 252.68 107.91 411.35 117.21 403.06 117.21 252.68 107.91 252.68"  />
                    <polygon points="56.84 273.41 56.84 453.26 66.15 453.51 66.15 273.41 56.84 273.41"  />
                    <polygon points="31.31 273.41 31.31 451.4 40.62 451.54 40.62 273.41 31.31 273.41"  />
                    <rect x="82.38" y="276.17" width="9.3" height="127.02"  />
                    <polygon points="82.38 411.49 82.38 434.11 91.68 425.82 91.68 411.49 82.38 411.49"    />
                    <rect x="5.76" y="273.66" width="9.3" height="61.54"  />
                    <polygon points="94.13 238.69 103.43 238.69 104.23 202.1 94.92 202.1 94.13 238.69"  />
                    <polygon points="110.76 238.69 120.06 238.69 120.86 202.1 111.55 202.1 110.76 238.69"  />
                    <polygon points="5.78 343.49 5.78 450.82 15.08 451.01 15.08 343.49 5.78 343.49"  />
                    <polygon points="68.72 190.19 68.72 183.27 60.95 190.19 68.72 190.19"  />
                    <rect x="5.77" y="334.25" width="31.92" height="9.37"  />
                    <polygon points="7.62 233.76 83.36 236.51 83.99 227.79 8.07 224.98 7.62 233.76"  />
                    <polygon points="119.78 189.18 119.78 137.75 110.48 146.04 110.48 189.18 119.78 189.18"  />
                    <polygon points="94.25 189.16 94.25 160.51 84.94 168.8 84.94 189.16 94.25 189.16"  />
                    <rect x="82.37" y="402.32" width="31.92" height="9.37"  />
                    <polygon points="130.7 433.2 130.7 454.3 140 454.42 140 433.2 130.7 433.2"  />
                    <rect x="82.57" y="446.26" width="31.92" height="8.29"  />
                    <polygon points="140.42 423.47 140.42 373.01 131.12 381.31 131.12 423.47 140.42 423.47"  />
                    <polygon points="117.21 454.55 117.21 403.06 107.91 411.35 107.91 454.55 117.21 454.55"  />
                    <polygon points="91.68 454.47 91.68 425.82 82.38 434.11 82.38 454.47 91.68 454.47"  />
                    <rect x="8.35" y="69.26" width="31.92" height="9.37"  />
                    <rect x="84.94" y="137.54" width="31.92" height="9.37"  />
                </g>
                </pattern>
                <rect x="0" y="0" width="100%" height="100%" fill={`url(#pattern-circles${props.number})`} />
            </svg>
        </>
    )
}