import React, { useEffect, useState } from 'react'

const MyInstaller = ({ children, language, componentContent }) => {
    const [installEvent, setInstallEvent] = useState() 
    const [installEventIos, setInstallEventIos] = useState(false) 
    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (event) => {
            console.log(event)
            event.preventDefault()
            setInstallEvent(event)
        })
        setInstallEventIos(isIos() && !isInStandaloneMode())

    }, [])

    const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test( userAgent );
    }

    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
   

    return ( 
    <>
    {installEvent && ( 
        <button
            style={{position:'relative', left:'10px',top:'99px', zIndex:'100', color:'#B66A00', border:'2px solid #B66A00', backgroundColor:'#fdfdfd', display:'flex', alignItems:'flex-start', width: language === 'de' ? '235px' : ''}}
            onClick={async () => { 
                installEvent.prompt()
                    await installEvent.userChoice 
                setInstallEvent(null)
            }} 
        >
            {installLogo()}
            <span style={{marginLeft:'10px'}}>
                {/* {
                    language === "de" ?
                    <>Installieren Sie diese App!</>
                    :
                    <>Install this app!</>
                } */}
                {componentContent}
            </span>
        </button>
    )}
    {children}
    </>
    ) 
}
export default MyInstaller

const installLogo  = () => {
    return(
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7 10.4133H11.1133C10.9267 10.4133 10.78 10.2667 10.78 10.08C10.78 9.89333 10.9267 9.74666 11.1133 9.74666H12.7C13.3 9.74666 13.88 9.48667 14.2867 9.04C14.9067 8.47333 15.2333 7.73333 15.2333 6.93999C15.2333 5.39333 13.9733 4.13333 12.4267 4.13333C12.1333 4.13333 11.8467 4.18 11.5667 4.26666C11.4733 4.3 11.3667 4.28666 11.28 4.22666C11.1933 4.17333 11.14 4.08 11.1333 3.98C10.9467 2.09333 9.37333 0.67333 7.48 0.67333C5.45333 0.67333 3.80667 2.31999 3.80667 4.34666C3.80667 4.58666 3.83333 4.82666 3.88 5.06666C3.9 5.16666 3.88 5.26666 3.81333 5.34C3.75333 5.42 3.65333 5.46 3.55333 5.46H2.80667C1.62667 5.46 0.666667 6.42 0.666667 7.6C0.666667 8.78 1.62667 9.74 2.80667 9.74H4.78C4.96667 9.74 5.11333 9.88667 5.11333 10.0733C5.11333 10.26 4.96667 10.4067 4.78 10.4067H2.80667C1.26 10.4067 0 9.14666 0 7.6C0 6.05333 1.26 4.79334 2.80667 4.79334H3.16667C3.15333 4.64 3.14 4.49333 3.14 4.34C3.14 1.94666 5.08667 0 7.48 0C9.59333 0 11.36 1.48666 11.74 3.52666C11.9667 3.48 12.1933 3.46 12.4267 3.46C14.34 3.46 15.9 5.02 15.9 6.93333C15.9 7.90666 15.4867 8.84666 14.76 9.50666C14.2467 10.0733 13.4933 10.4067 12.7067 10.4067L12.7 10.4133Z" fill="#B66A00"/>
            <path d="M7.95326 15.3866C7.8866 15.3866 7.81326 15.3666 7.75993 15.3199L3.9066 12.5133C3.75993 12.4066 3.7266 12.1933 3.83327 12.0466C3.93993 11.8999 4.1466 11.8666 4.29993 11.9733L7.95993 14.6399L11.6199 11.9733C11.7666 11.8666 11.9799 11.8999 12.0866 12.0466C12.1933 12.1933 12.1599 12.4066 12.0133 12.5133L8.15326 15.3199C8.09326 15.3599 8.0266 15.3866 7.95993 15.3866H7.95326Z" fill="#B66A00"/>
            <path d="M7.95321 15.3866C7.76654 15.3866 7.61987 15.2399 7.61987 15.0533V6.51326C7.61987 6.3266 7.76654 6.17993 7.95321 6.17993C8.13987 6.17993 8.28654 6.3266 8.28654 6.51326V15.0533C8.28654 15.2399 8.13987 15.3866 7.95321 15.3866Z" fill="#B66A00"/>
        </svg>
    )
}