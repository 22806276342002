import React from 'react';
import { useRef, useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import classes from './Meaning.module.css'

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const parseJSON = (resp) => (resp.json ? resp.json() : resp);
const checkStatus = (resp) => {
    if (resp.status >= 200 && resp.status < 300) {
      return resp;
    }
  
    return parseJSON(resp).then(resp => {
      throw resp;
    });
};




export default function Meaning({
    pageContent={pageContent},
    basicDictionary = false,
    openDictionary = false,
    bookDictionary = false,
    termNotSelected,
    meaning, 
    meaningHasSynonym,
    id, 
    language,
    onClick4 = f => f, 
    listOfCurrentTraductions, 
    meaningTranslation, 
    searchLanguage, 
    isLoadingMeaningTranslation, 
    setIsLoadingMeaningTranslation = f => f, 
    mobile,
    setMobileMeaningStyle = f => f, 
    mobileMeaningStyle, 
    setShareId = f => f,
    skeletonWidth,
    setSkeletonWidth = f => f,
    timeToFetch,
    // spaceTaken,
    // setSpaceTaken = f => f
}) {



    function createMarkup(str) {
        return {__html: str};
    }



    const [blurSize, setBlurSize] = useState({
        blurWidth: '',
        blurHeight: '',
        blurTop: ''

    })

    const [spaceTaken, setSpaceTaken] = useState(0)

    const [titleHeight, setTitleHeight] = useState(0)

    // useEffect(() =>{
    //     if(id !== "0" && openDictionary && !termNotSelected) {
    //         setTimeout(() => {
    //             let dangerouslySetInnerHTML = document.getElementById('dangerouslySetInnerHTML').getElementsByTagName("a")
    //             Object.values(dangerouslySetInnerHTML).map(a => {
    //                 a.classList.add('tooltipTop')
    //                 a.href="javascript:void(0)"
    //             })
    //             Object.values(document.getElementsByClassName('tooltipTop')).map(a => a.appendChild(document.createElement("span")) )
    //             let tooltipContainer = document.getElementById('dangerouslySetInnerHTML').getElementsByTagName("span")
    //             Object.values(tooltipContainer).map(a=>a.classList.add('tooltipTopText'))
    //             Object.values(tooltipContainer).map(a=>a.innerHTML =  `<a class="link" style="color:#B66A00 !important;cursor: pointer;" href="/registration">Register</a> to use link`)
    //             console.log("Again")
    //         }, timeToFetch+ 5000);
    //     }
    // },[id, openDictionary, termNotSelected, timeToFetch])


    // const blockInternalLinks = () => {
    //     if(id !== "0" && openDictionary && meaning.free) {
    //         setTimeout(() => {
    //             let dangerouslySetInnerHTML = document.getElementById('dangerouslySetInnerHTML').getElementsByTagName("a")
    //             Object.values(dangerouslySetInnerHTML).map(a => {
    //                 a.classList.add('tooltipTop')
    //                 a.href="javascript:void(0)"
    //             })
    //             Object.values(document.getElementsByClassName('tooltipTop')).map(a => a.appendChild(document.createElement("span")) )
    //             let tooltipContainer = document.getElementById('dangerouslySetInnerHTML').getElementsByTagName("span")
    //             Object.values(tooltipContainer).map(a=>a.classList.add('tooltipTopText'))
    //             Object.values(tooltipContainer).map(a=>a.innerHTML =  `<a class="link" style="color:#B66A00 !important;cursor: pointer;" href="/registration">Register</a> to use link`)
    //         }, timeToFetch + 3000);
    //     }
    // }

    const ref = useRef(null)
    const testRef = useRef(null)

    useEffect(() => {
        

        if (  !isLoadingMeaningTranslation) {
            if(mobile && ref.current){
                setSpaceTaken(document.getElementById('termTitle').getBoundingClientRect().height + document.getElementById('termSubtitle').getBoundingClientRect().height + document.getElementById('termSynoyms').getBoundingClientRect().height + 35)
            } else if(!mobile && ref.current) {
                setSpaceTaken(document.getElementById('termTitle').getBoundingClientRect().height + document.getElementById('termSubtitle').getBoundingClientRect().height + document.getElementById('termSynoyms').getBoundingClientRect().height + 35)
            } else if (!mobile && (basicDictionary || openDictionary) && ref.current) {
                setSpaceTaken(document.getElementById('termTitle').getBoundingClientRect().height + document.getElementById('termSubtitle').getBoundingClientRect().height + document.getElementById('termSynoyms').getBoundingClientRect().height + 35 + 21)
            } else if(mobile && (basicDictionary || openDictionary) && ref.current) {
                setSpaceTaken(document.getElementById('termTitle').getBoundingClientRect().height + document.getElementById('termSubtitle').getBoundingClientRect().height + document.getElementById('termSynoyms').getBoundingClientRect().height + 35 + 42)
            } 
        }
        
    })
    
    
    

    return(
        termNotSelected
        ?
        <>
            <div style={{width:'100%'}}>
                <div style={{backgroundColor: bookDictionary ? '#F6F9E5' : '#E0F2F0'}} className={classes.meaningTermBoxNotSelected}>
                    {/* <div id='dangerouslySetInnerHTML' style={{display:'none'}}/> */}
                    <h1>
                        {/* {
                            language === "de"
                            ?
                            <>Wählen Sie einen Begriff</>
                            :
                            <>Choose a term</>
                        } */}
                        {pageContent.choose_term}
                    </h1>
                </div>
            </div>
        </>
        :
        <>
           
            <div ref={ref} style={{width:'100%'}}>
                <div style={{backgroundColor: bookDictionary ? '#F6F9E5' : '#E0F2F0'}} className={classes.meaningTermBox}>
                    <div className={classes.meaningTermCallToAction}>
                    {
                        mobile
                        ?
                        <>{
                            mobileMeaningStyle
                            ?
                            <button className={`${classes.meaningTermBackButton} none`} onClick={() =>  {setMobileMeaningStyle(false), setSpaceTaken(0)}}>
                                <i className='icon icon-arrow'></i>
                            </button>
                            : 
                            null
                        }</>
                        
                        : 
                        null
                    }
                    
                        <button 
                            className={`${classes.meaningTermShareButton} none`}
                            onClick={() => {setShareId(id, null)}}
                            disabled={openDictionary?true:false}
                        >
                            <i
                            style={{color: openDictionary ? 'grey': '#B66A00', cursor: openDictionary?'not-allowed':'pointer'}} className='tooltip icon icon-share'>
                            {
                                openDictionary
                                ?
                                <>
                                <span className="tooltiptext">
                                    <span dangerouslySetInnerHTML={createMarkup(pageContent.register_to_share)}/>
                                </span>
                                </>
                                :
                                <></>
                            }
                            </i>
                        </button>
                    </div>

                    <div className={classes.meaningTermTitle} >
                        <h3 ref={testRef} id="termTitle">{meaning.text} </h3>  
                    </div>

                    <div>
                        <div className={classes.changeLanguageButtons}>
                            {(() => {
                                if (searchLanguage === 'de') {
                                return (
                                    <>
                                    <button 
                                        onClick={() => {onClick4(id); setIsLoadingMeaningTranslation(true); }} 
                                        style={{backgroundColor: meaningTranslation === false || meaningTranslation.locale === 'de' ? '#BD8F16': '#FDFDFD', color: meaningTranslation === false || meaningTranslation.locale === 'de' ? '#FDFDFD' :'#BD8F16'}} 
                                        className={`none ${classes.changeLanguageButton}`}
                                    >
                                        {
                                            mobile
                                            ?
                                            <>DE</>
                                            :
                                            <>{pageContent.lang_name_de}</>
                                        }
                                    </button>
                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'fr') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'fr' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'fr' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>FR</>
                                                    :
                                                    <>{pageContent.lang_name_fr}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'fr') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'fr' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'fr' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>FR</>
                                                    :
                                                    <>{pageContent.lang_name_fr}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'fr'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'fr' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'fr' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>FR</>
                                                    :
                                                    <>{pageContent.lang_name_fr}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}
                                        {(() => {
                                        if (listOfCurrentTraductions[1] === 'it') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'it' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'it' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>IT</>
                                                    :
                                                    <>{pageContent.lang_name_it}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'it') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'it' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'it' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>IT</>
                                                    :
                                                    <>{pageContent.lang_name_it}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'it'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'it' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'it' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>IT</>
                                                    :
                                                    <>{pageContent.lang_name_it}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'en') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'en' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'en' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>EN</>
                                                    :
                                                    <>{pageContent.lang_name_en}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'en') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'en' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'en' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>EN</>
                                                    :
                                                    <>{pageContent.lang_name_en}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'en'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'en' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'en' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>EN</>
                                                    :
                                                    <>{pageContent.lang_name_en}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}
                                    </>
                                )
                                } else if (searchLanguage === 'fr') {
                                return (
                                    <>
                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'de') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'de' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'de' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>DE</>
                                                    :
                                                    <>{pageContent.lang_name_de}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'de') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); setSkeletonWidth(document.getElementById('meaningText' || 'meaningTextOpen').offsetWidth )}} 
                                                style={{backgroundColor: meaningTranslation.locale === 'de' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'de' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>DE</>
                                                    :
                                                    <>{pageContent.lang_name_de}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'de'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'de' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'de' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>DE</>
                                                    :
                                                    <>{pageContent.lang_name_de}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}

                                    <button 
                                        onClick={() => {onClick4(id); setIsLoadingMeaningTranslation(true); }} 
                                        style={{backgroundColor: meaningTranslation === false || meaningTranslation.locale === 'fr' ? '#BD8F16': '#FDFDFD', color: meaningTranslation === false || meaningTranslation.locale === 'fr' ? '#FDFDFD' :'#BD8F16'}} 
                                        className={`none ${classes.changeLanguageButton}`}
                                    >
                                        {
                                            mobile
                                            ?
                                            <>FR</>
                                            :
                                            <>{pageContent.lang_name_fr}</>
                                        }
                                    </button>
                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'it') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'it' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'it' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>IT</>
                                                    :
                                                    <>{pageContent.lang_name_it}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'it') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'it' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'it' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>IT</>
                                                    :
                                                    <>{pageContent.lang_name_it}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'it'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'it' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'it' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>IT</>
                                                    :
                                                    <>{pageContent.lang_name_it}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}

                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'en') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'en' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'en' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>EN</>
                                                    :
                                                    <>{pageContent.lang_name_en}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'en') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'en' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'en' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>EN</>
                                                    :
                                                    <>{pageContent.lang_name_en}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'en'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'en' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'en' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>EN</>
                                                    :
                                                    <>{pageContent.lang_name_en}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}

                                    </>
                                )
                                } else if (searchLanguage === 'it'){
                                return (
                                    <>
                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'de') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'de' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'de' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>DE</>
                                                    :
                                                    <>{pageContent.lang_name_de}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'de') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'de' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'de' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>DE</>
                                                    :
                                                    <>{pageContent.lang_name_de}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'de'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'de' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'de' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>DE</>
                                                    :
                                                    <>{pageContent.lang_name_de}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'fr') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'fr' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'fr' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>FR</>
                                                    :
                                                    <>{pageContent.lang_name_fr}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'fr') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'fr' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'fr' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>FR</>
                                                    :
                                                    <>{pageContent.lang_name_fr}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'fr'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'fr' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'fr' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>FR</>
                                                    :
                                                    <>{pageContent.lang_name_fr}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}

                                    <button 
                                        onClick={() => {onClick4(id); setIsLoadingMeaningTranslation(true); }} 
                                        style={{backgroundColor: meaningTranslation === false || meaningTranslation.locale === 'it' ? '#BD8F16': '#FDFDFD', color: meaningTranslation === false || meaningTranslation.locale === 'it' ? '#FDFDFD' :'#BD8F16'}} 
                                        className={`none ${classes.changeLanguageButton}`}
                                    >
                                        {
                                            mobile
                                            ?
                                            <>IT</>
                                            :
                                            <>{pageContent.lang_name_it}</>
                                        }
                                    </button>
                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'en') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'en' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'en' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>EN</>
                                                    :
                                                    <>{pageContent.lang_name_en}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'en') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'en' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'en' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>EN</>
                                                    :
                                                    <>{pageContent.lang_name_en}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'en'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'en' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'en' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>EN</>
                                                    :
                                                    <>{pageContent.lang_name_en}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}
                                    </>
                                )
                                } else if (searchLanguage === 'en'){
                                return (
                                    <>
                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'de') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'de' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'de' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>DE</>
                                                    :
                                                    <>{pageContent.lang_name_de}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'de') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'de' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'de' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>DE</>
                                                    :
                                                    <>{pageContent.lang_name_de}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'de'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'de' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'de' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>DE</>
                                                    :
                                                    <>{pageContent.lang_name_de}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'fr') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'fr' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'fr' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>FR</>
                                                    :
                                                    <>{pageContent.lang_name_fr}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'fr') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'fr' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'fr' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>FR</>
                                                    :
                                                    <>{pageContent.lang_name_fr}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'fr'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'fr' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'fr' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>FR</>
                                                    :
                                                    <>{pageContent.lang_name_fr}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}
                                    {(() => {
                                        if (listOfCurrentTraductions[1] === 'it') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[0]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'it' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'it' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>IT</>
                                                    :
                                                    <>{pageContent.lang_name_it}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[3] === 'it') {
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[2]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'it' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'it' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>IT</>
                                                    :
                                                    <>{pageContent.lang_name_it}</>
                                                }
                                            </button>
                                        )
                                        } else if (listOfCurrentTraductions[5] === 'it'){
                                        return (
                                            <button 
                                                onClick={() => {onClick4(listOfCurrentTraductions[4]); setIsLoadingMeaningTranslation(true); }} 
                                                style={{backgroundColor: meaningTranslation.locale === 'it' ? '#BD8F16': '#FDFDFD', color: meaningTranslation.locale === 'it' ? '#FDFDFD' :'#BD8F16'}} 
                                                className={`none ${classes.changeLanguageButton}`}
                                            >
                                                {
                                                    mobile
                                                    ?
                                                    <>IT</>
                                                    :
                                                    <>{pageContent.lang_name_it}</>
                                                }
                                            </button>
                                        )
                                        }
                                    })()}




                                    <button 
                                        onClick={() => {onClick4(id); setIsLoadingMeaningTranslation(true); }} 
                                        style={{backgroundColor: meaningTranslation === false || meaningTranslation.locale === 'en' ? '#BD8F16': '#FDFDFD', color: meaningTranslation === false || meaningTranslation.locale === 'en' ? '#FDFDFD' :'#BD8F16'}} 
                                        className={`none ${classes.changeLanguageButton}`}
                                    >
                                        {
                                            mobile
                                            ?
                                            <>EN</>
                                            :
                                            <>{pageContent.lang_name_en}</>
                                        }
                                    </button>
                                    </>
                                )
                                }
                            })()}
                        </div>
                        {
                            (openDictionary || basicDictionary) && meaning.free && (!meaningTranslation || meaningTranslation.locale === "de")
                            ?
                            <>
                            <span className={classes.openDictionaryLinks}>
                                {/* {
                                    language === 'de'
                                    ?
                                    <>*Um die Links nutzen zu können, müssen Sie über ein<Link to={`/aboutthebook`}> Buchkonto </Link> verfügen</>
                                    :
                                    <>*To use the links, please you must have a  <Link to={`/aboutthebook`}> book account</Link></>
                                } */}
                                <div dangerouslySetInnerHTML={createMarkup(pageContent.use_links_text)}/>
                            </span>
                            </>
                            :
                            <></>
                        }

                        <div style={{height: window.innerHeight < 900 && (!basicDictionary || !openDictionary) ? 'calc(85vh - 70px - 62px)':'calc(85vh - 70px - 132px)'}} className={classes.meaningTermContainer}>
                            {
                                isLoadingMeaningTranslation
                                ?
                                <>
                                <SkeletonTheme
                                    baseColor="#E1E2E1"
                                    highlightColor="#FDFDFD"
                                    borderRadius="10"
                                    duration={2}
                                >  
                                    <div className={classes.meaningTermSkeleton}>
                                        <div >
                                            <Skeleton />
                                        </div>
                                        <div>
                                            <Skeleton count={11} />
                                        </div>
                                    </div>   
                                </SkeletonTheme>
                                </>
                                :
                                <>
                                <div className={classes.meaningTermSubtitleAndSynoyms}>
                                    {
                                        meaningTranslation.locale === "de" || meaningTranslation === false
                                        ?
                                        <>
                                            {(() => {
                                                if (!meaningTranslation) {
                                                    return (
                                                        <>
                                                        <div id='termSubtitle' className={classes.meaningTermSubtitle} >{meaning.text}</div> 
                                                        </>
                                                    )
                                                } else if ( meaningTranslation) {
                                                    return (
                                                        <>
                                                        <div id='termSubtitle' className={classes.meaningTermSubtitle} >{meaningTranslation.text}</div> 
                                                        </>
                                                    )
                                                } 
                                            })()}
                                            {
                                                openDictionary 
                                                ?
                                                <>
                                                <div id='termSynoyms' className={classes.meaningTermSynoyms}>   
                                                    {(() => {
                                                        if (Object.keys(meaning).length === 0) {
                                                            return (
                                                                <></>
                                                            )
                                                        } else if (Object.keys(meaning.synonyms).length === 1 ) {
                                                            return (
                                                                <>{pageContent.Synonym}: {meaning.synonyms[0].text}</>
                                                            ) 
                                                        } 
                                                        else if (Object.keys(meaning.synonyms).length === 2 ) {
                                                            return (
                                                                <>{pageContent.Synonyms}: {meaning.synonyms[0].text}, {meaning.synonyms[1].text}</>
                                                            )
                                                        }
                                                        else if (Object.keys(meaning.synonyms).length === 3 ) {
                                                            return (
                                                                <>{pageContent.Synonyms}: {meaning.synonyms[0].text}, {meaning.synonyms[1].text}, {meaning.synonyms[2].text}</>
                                                            )
                                                        }
                                                        else if (Object.keys(meaning.synonyms).length === 4 ) {
                                                            return (
                                                                <>{pageContent.Synonyms}: {meaning.synonyms[0].text}, {meaning.synonyms[1].text}, {meaning.synonyms[2].text}, {meaning.synonyms[3].text}</>
                                                            )
                                                        }
                                                        else if (Object.keys(meaning.synonyms).length === 5 ) {
                                                            return (
                                                                <>{pageContent.Synonyms}: {meaning.synonyms[0].text}, {meaning.synonyms[1].text}, {meaning.synonyms[2].text}, {meaning.synonyms[3].text}, {meaning.synonyms[4].text}</>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <></>
                                                            )
                                                        }
                                                    })()}
                                                </div>
                                                </>
                                                :
                                                <>

                                                    {
                                                        searchLanguage === meaningTranslation.locale || !meaningTranslation
                                                        ?
                                                        <>
                                                        <div id='termSynoyms' className={classes.meaningTermSynoyms}>   
                                                        {
                                                            {...{...{...{...meaning.synonyms}.data}[0]}.attributes}.text !== undefined && {...{...{...{...meaning.synonyms}.data}[1]}.attributes}.text === undefined
                                                            ?
                                                            <>{pageContent.Synonym}: {{...{...{...{...meaning.synonyms}.data}[0]}.attributes}.text}</>
                                                            :
                                                            <></>
                                                        }
                                                        {
                                                            {...{...{...{...meaning.synonyms}.data}[1]}.attributes}.text !== undefined && {...{...{...{...meaning.synonyms}.data}[2]}.attributes}.text === undefined
                                                            ?
                                                            <>{pageContent.Synonyms}: {{...{...{...{...meaning.synonyms}.data}[0]}.attributes}.text}, {{...{...{...{...meaning.synonyms}.data}[1]}.attributes}.text}</>
                                                            :
                                                            <></>
                                                        }
                                                        {
                                                            {...{...{...{...meaning.synonyms}.data}[2]}.attributes}.text !== undefined && {...{...{...{...meaning.synonyms}.data}[3]}.attributes}.text === undefined
                                                            ?
                                                            <>{pageContent.Synonyms}: {{...{...{...{...meaning.synonyms}.data}[0]}.attributes}.text}, {{...{...{...{...meaning.synonyms}.data}[1]}.attributes}.text}, {{...{...{...{...meaning.synonyms}.data}[2]}.attributes}.text}</>
                                                            :
                                                            <></>
                                                        }
                                                        {
                                                            {...{...{...{...meaning.synonyms}.data}[3]}.attributes}.text !== undefined && {...{...{...{...meaning.synonyms}.data}[4]}.attributes}.text === undefined
                                                            ?
                                                            <>{pageContent.Synonyms}: {{...{...{...{...meaning.synonyms}.data}[0]}.attributes}.text}, {{...{...{...{...meaning.synonyms}.data}[1]}.attributes}.text}, {{...{...{...{...meaning.synonyms}.data}[2]}.attributes}.text}, {{...{...{...{...meaning.synonyms}.data}[3]}.attributes}.text}</>
                                                            :
                                                            <></>
                                                        }
                                                            
                                                        </div>
                                                        </>
                                                        :
                                                        <>
                                                         <div id='termSynoyms' className={classes.meaningTermSynoyms}>   
                                                        {
                                                            {...{...{...{...meaningTranslation.synonyms}.data}[0]}.attributes}.text !== undefined && {...{...{...{...meaningTranslation.synonyms}.data}[1]}.attributes}.text === undefined
                                                            ?
                                                            <>{pageContent.Synonym}: {{...{...{...{...meaningTranslation.synonyms}.data}[0]}.attributes}.text}</>
                                                            :
                                                            <></>
                                                        }
                                                        {
                                                            {...{...{...{...meaningTranslation.synonyms}.data}[1]}.attributes}.text !== undefined && {...{...{...{...meaningTranslation.synonyms}.data}[2]}.attributes}.text === undefined
                                                            ?
                                                            <>{pageContent.Synonyms}: {{...{...{...{...meaningTranslation.synonyms}.data}[0]}.attributes}.text}, {{...{...{...{...meaningTranslation.synonyms}.data}[1]}.attributes}.text}</>
                                                            :
                                                            <></>
                                                        }
                                                        {
                                                            {...{...{...{...meaningTranslation.synonyms}.data}[2]}.attributes}.text !== undefined && {...{...{...{...meaningTranslation.synonyms}.data}[3]}.attributes}.text === undefined
                                                            ?
                                                            <>{pageContent.Synonyms}: {{...{...{...{...meaningTranslation.synonyms}.data}[0]}.attributes}.text}, {{...{...{...{...meaningTranslation.synonyms}.data}[1]}.attributes}.text}, {{...{...{...{...meaningTranslation.synonyms}.data}[2]}.attributes}.text}</>
                                                            :
                                                            <></>
                                                        }
                                                        {
                                                            {...{...{...{...meaningTranslation.synonyms}.data}[3]}.attributes}.text !== undefined && {...{...{...{...meaningTranslation.synonyms}.data}[4]}.attributes}.text === undefined
                                                            ?
                                                            <>{pageContent.Synonyms}: {{...{...{...{...meaningTranslation.synonyms}.data}[0]}.attributes}.text}, {{...{...{...{...meaningTranslation.synonyms}.data}[1]}.attributes}.text}, {{...{...{...{...meaningTranslation.synonyms}.data}[2]}.attributes}.text}, {{...{...{...{...meaningTranslation.synonyms}.data}[3]}.attributes}.text}</>
                                                            :
                                                            <></>
                                                        }
                                                            
                                                        </div>
                                                        </>
                                                    }
                                                   
                                                </>
                                            }
                                            
                                          
                                        </>
                                        :
                                        <>
                                            <div id='termSubtitle' className={classes.meaningTermSubtitle}>{meaningTranslation.text}</div> 
                                            <div id='termSynoyms' className={classes.meaningTermSynoyms}>   
                                                {
                                                    meaningTranslation.synonyms.data[0] !== undefined && meaningTranslation.synonyms.data[1] === undefined
                                                    ?
                                                    <>{pageContent.Synonym}: {meaningTranslation.synonyms.data[0].attributes.text}</>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    meaningTranslation.synonyms.data[1] !== undefined && meaningTranslation.synonyms.data[2] === undefined
                                                    ?
                                                    <>{pageContent.Synonyms}: {meaningTranslation.synonyms.data[0].attributes.text}, {meaningTranslation.synonyms.data[1].attributes.text}</>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    meaningTranslation.synonyms.data[2] !== undefined && meaningTranslation.synonyms.data[3] === undefined
                                                    ?
                                                    <>{pageContent.Synonyms}: {meaningTranslation.synonyms.data[0].attributes.text}, {meaningTranslation.synonyms.data[1].attributes.text}, {meaningTranslation.synonyms.data[2].attributes.text}</>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    meaningTranslation.synonyms.data[3] !== undefined && meaningTranslation.synonyms.data[4] === undefined
                                                    ?
                                                    <>{pageContent.Synonyms}: {meaningTranslation.synonyms.data[0].attributes.text}, {meaningTranslation.synonyms.data[1].attributes.text}, {meaningTranslation.synonyms.data[2].attributes.text}, {meaningTranslation.synonyms.data[3].attributes.text}</>
                                                    :
                                                    <></>
                                                }
                                                {
                                                    meaningTranslation.synonyms.data[4] !== undefined && meaningTranslation.synonyms.data[5] === undefined
                                                    ?
                                                    <>{pageContent.Synonyms}: {meaningTranslation.synonyms.data[0].attributes.text}, {meaningTranslation.synonyms.data[1].attributes.text}, {meaningTranslation.synonyms.data[2].attributes.text}, {meaningTranslation.synonyms.data[3].attributes.text}, {meaningTranslation.synonyms.data[4].attributes.text}</>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </>
                                    }
                                
                                </div>
                                <div id={ openDictionary || basicDictionary ? 'meaningTextOpen' :'meaningText'} className={classes.meaningTermText} style={{height: bookDictionary?`calc(100vh - 180px - ${spaceTaken}px - 55px)`:`calc(100vh - 180px - ${spaceTaken}px - 95px)`}}>
                                    {
                                        openDictionary || basicDictionary
                                        ?
                                        <>
                                        {
                                            (meaningTranslation===false || meaningTranslation.locale === "de") && meaning.free 
                                            ?
                                            <>
                                                <div id='dangerouslySetInnerHTML' className={openDictionary || basicDictionary?classes.openDictionaryMeaning:''} dangerouslySetInnerHTML={createMarkup(meaning.definition)} />
                                            </>
                                            :
                                          
                                            <>
                                            <div className={classes.willBeAvailableSoonContainer}>
                                                <div className={classes.willBeAvailableSoon}>
                                                    <i className='icon-upgrade'></i>
                                                    <span>{pageContent.get_book_account}</span>
                                                    <Link
                                                            to={`/abouttheapp`}
                                                            className={'btn ochre size-auto'}
                                                            style={{color: 'white', textDecoration: 'none'}}
                                                        >
                                                            {pageContent.get_book_account_button}
                                                    </Link>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        </>
                                        :
                                        <>
                                            {(() => {
                                                if (((meaning.definition === "" || meaning.definition === null) && !meaningTranslation) || ( meaningTranslation.definition === "" || meaningTranslation.definition === null)) {
                                                    return (
                                                        <>
                                                        <div style={{backgroundColor:bookDictionary ? '#E0F2F0':'#F6F9E5'}} className={classes.willBeAvailableSoonContainer}>
                                                            <div className={classes.willBeAvailableSoon}>
                                                                <i className='icon icon-lock'></i>
                                                                <span>{pageContent.available_soon}</span>
                                                            </div>
                                                        </div>
                                                        </>
                                                    )
                                                } else if (meaning.definition !== "" && !meaningTranslation) {
                                                    return (
                                                        <>
                                                        <div id='dangerouslySetInnerHTML' className={openDictionary || basicDictionary?classes.openDictionaryMeaning:''} dangerouslySetInnerHTML={createMarkup(meaning.definition)} />
                                                        </>
                                                    )
                                                } else if (meaningTranslation.definition !== ""){
                                                    return (
                                                        <>
                                                        <div id='dangerouslySetInnerHTML' className={openDictionary || basicDictionary?classes.openDictionaryMeaning:''} dangerouslySetInnerHTML={createMarkup(meaningTranslation.definition)} />
                                                        </>
                                                    )
                                                } 
                                            })()}
                                        </>
                                    }
                                    
                                    
                                   

                                   
                                </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

