import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

import classes from '../Pages/ConfirmationRegistration.module.css'

export default function ConfirmationRegistration(props) {
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/getid/${props.match.params.id}`, {method: "GET"})
        .then(response => response.json())
        .then(data => {
            fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/confirm/${data.ID}`, {method: "POST"})
        })
    }, [])

    return(
        <>
            <div className={classes.ConfirmationContainer}>
                <div className={classes.Confirmation}>
                    <div>
                        <h3>WELCOME TO ACCOUNTING A-Z</h3>
                    </div>
                    <div>
                        <i className='icon-confirm icon-success'></i>
                    </div>
                    <p className='body_text'>
                        <div>
                            Your account was ACTIVATED successfully.
                        </div>
                        <div >
                            Now you can login and start using the digital dictionary.
                        </div>
                        <div className={classes.CallToAction}>
                            <Link
                                to={`/login`}
                                className={'btn ochre'}
                            >
                                Go to login
                            </Link>
                        </div>
                    </p>
                </div>
            </div>
        </>
    )
}

