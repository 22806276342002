import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import AppHeader from '../Components/AppHeader';
import AppFooter from '../Components/AppFooter';

import classes from './ConfirmChangePassword.module.css'

export default function ConfirmChangePassword(props) {
    const [comeFrom, setComeFrom] = useState(window.localStorage.getItem("comeFrom"))

    let id = window.localStorage.getItem("id")
    return(
        <>
        <AppHeader 
            currentPathName={props.location.pathname}
            appLanguage={props.language}
            comeFrom={comeFrom}
        />
        <div className={classes.Container}>
            <div className={classes.Content}>
                <div>
                    <h3>Successful change</h3>
                </div>
                <div>
                    <i className='icon-confirm icon-lock'></i>
                </div>
                <div className='body_text'>
                    <div>
                        Your password was updated
                    </div>
                </div>
                <div>
                    <Link
                        to={`/profile/${id}`}
                        className={'btn ochre'}
                    >
                        Go back
                    </Link>
                </div>
            </div>
        </div>
        <AppFooter language={props.language} />
        </>
    )
}

