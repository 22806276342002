import React from 'react';
// import classes from './CheckboxInput.module.css'

export default function StarsInput({rate, changeRate = f=> f, changeRateBack = f=> f, disabled, errorDiv, errorMsg, language, componentContent}) {

    return(
        <>
            <p style={{fontFamily:"'Roboto'"}}>
                {/* {
                    language === 'de'
                    ?
                    <>Bewerten Sie die App*</>
                    :
                    <>Rate the app*</>
                } */}
                {componentContent}
            </p>

            <div style={{height:'50px'}}>
            {
                Array.from(Array(parseInt(rate)).keys()).map(i => {
                    return (
                        <svg 
                            style={{cursor:'pointer'}} 
                            onClick={() => {
                                changeRate(i+1)
                            }} 
                            id={i} 
                            width="30" height="28.5" viewBox="0 0 30 28.5" fill="none" xmlns="http://www.w3.org/2000/svg"
                        >
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27Z" fill="#F3BF4C"/>
                        </svg>
                    )
                })
            }
            {Array.from(Array(5- parseInt(rate)).keys()).map(i => {
                return (
                    <svg 
                        style={{cursor:'pointer'}} 
                        onClick={() => {
                            changeRateBack( parseInt(rate) + i + 1)
                        }} 
                        id={rate + i} 
                        width="30" height="28.5" viewBox="2 2 30 28.5" fill="none" xmlns="http://www.w3.org/2000/svg"
                    >
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.24L14.81 8.62L12 2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27L18.18 21L16.55 13.97L22 9.24ZM12 15.4L8.24 17.67L9.24 13.39L5.92 10.51L10.3 10.13L12 6.1L13.71 10.14L18.09 10.52L14.77 13.4L15.77 17.68L12 15.4Z" fill="#F3BF4C"/>
                    </svg>
                )
            })}
            <div className={errorDiv}>
                {errorMsg}
            </div>
            </div>

            
        </>
        
    );
}