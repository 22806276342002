import React, {useState} from 'react';

import Input from "../Components/Input";
import Button from "../Components/Button";

import classes from '../Pages/ForgotPassword.module.css'

import SvgPatternBackground from '../Components/svgPatternBackground';

export default function ForgotPassword(props) {
    const [txt, setTxt] = useState('')
    const initError = {
        exists: false,
        helperText: null,
    };
    const [emailError, setEmailError] = useState(initError)
    const [user, setUser] = useState({
        txt: "",
        lang: props.language
    })

    const handleChange = (evt) => {
        let value = evt.target.value;
        setTxt(value)
        setUser({
            txt: value,
            lang: props.language
        })
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();

        if(txt.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) !== null) {
            
            const requestOptions = {
                method: "GET",
            };

            if (props.language === "de") {
                fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/forgot/${txt}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data);   
                        window.location.href='/confirmationforgotpassword';
                    })
            } else {
                fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/forgotEN/${txt}`, requestOptions)
                    .then(response => response.json())
                    .then(data => {
                        console.log(data);   
                        window.location.href='/confirmationforgotpassword';
                    })
            }
    
            
        } else {
            setEmailError({
                exists: true,
                helperText: props.language === "de" ? "Schreiben Sie eine gültige E-Mail": "Write a valid email",
            });
        }



    };
    return(
        <>
        <div style={{height:'92vh'}} >
            <div className={classes.ForgotPasswordBox}>
                <div style={{height:'5%', borderRadius:'16px 16px 0 0'}}>
                    <SvgPatternBackground borderRadius={'16px 16px 0 0'} scale={2} number={1} height={'4vh'} color={'cad051'} angle={-45}/>
                </div>
                <div>
                    <h3 className={classes.ForgotPasswordTitle}>
                        {
                            props.language === "de"
                            ?
                            <>Ihr Passwort zurücksetzen</>
                            :
                            <>Reset your password</>
                        }
                    </h3>
                    <form style={{display:'grid', height:'300px', justifyContent:'center', alignContent:'space-evenly', justifyItems:'center'}} onSubmit={handleSubmit}>
                        <div className="body_text">
                        {
                            props.language === "de"
                            ?
                            <>Geben Sie Ihre E-Mail ein</>
                            :
                            <>Type your e-mail</>
                        } 
                        </div>
                        <div >
                            <Input 
                                title = {"email"}
                                type = {"text"}
                                name = {"txt"}
                                placeholder = {props.language === "de" ? "Benutzername / E-Mail": "User name / E-mail"}
                                handleChange={handleChange}
                                className={emailError.exists ? "is-invalid": ""}
                                errorDiv = {emailError.exists ? "text-danger" : "no-danger"}
                                errorMsg = {emailError.helperText}
                            />
                        </div>
                        <div>
                            <Button
                                title={props.language === "de" ? "E-Mail senden": "Send e-mail"}
                                className={"ochre"}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    )

}
