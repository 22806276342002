import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import "./fontello/css/fontello.css";

import Login from './Pages/Login';
import Registration from './Pages/Registration';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';

import Dictionary from './Pages/Dictionary';
import BasicDictionary from './Pages/BasicDictionary'


import WebFooter from './Components/WebFooter';
import WebHeader from './Components/WebHeader';
import HomePage from './Pages/HomePage';
import OpenDictionary from './Pages/OpenDictionary'
import AboutAppPage from './Pages/AboutAppPage';
import ConfirmationRegistration from './Pages/ConfirmationRegistration';
import AboutBookPage from './Pages/AboutBookPage';
import AboutAuthorPage from './Pages/AboutAuthorPage';
import NotFoundPage from './Pages/NotFoundPage';
import ConfirmationRegistrationMail from './Pages/ConfirmationRegistrationMail';
import ConfirmationForgotPasswordMail from './Pages/ConfirmationForgotPasswordMail';
import ConfirmationForgotPassword from './Pages/ConfirmationForgotPassword';
import ImprintPage from './Pages/ImprintPage';
import PrivacyNotice from './Pages/PrivacyNotice';
import TermsOfUse from './Pages/TermsOfUse';
import Profile from './Pages/Profile';

import CookiesModal from './Components/CookiesModal';

import CookieConsent from "react-cookie-consent";


import ChangePassword from './Pages/ChangePassword';
import SendFeedback from './Pages/SendFeedback';
import UpgradeAccount from './Pages/UpgradeAccount';
import UpgradeBookAccount from './Pages/UpgradeBookAccount';
import ConfirmChangePassword from './Pages/ConfirmChangePassword';


export default function App(){

  const [jwt, setJwt] = useState("")
  const [appLanguage, setAppLanguage] = useState("de")
  const [cookiesAccepted, setCookiesAccepted] =useState(false)

  // useEffect(() => {
  //   setJwt(window.localStorage.getItem("jwt"))
  //   if (document.cookie.indexOf('basic_cookie=') !== -1){
  //     setCookiesAccepted(true)
  //   }
  // }, [cookiesAccepted])
 
    return(
      <Router>
        <CookieConsent enableDeclineButton>This website uses cookies to enhance the user experience.</CookieConsent>
        {/* {
          cookiesAccepted === false
          ?
          <CookiesModal setCookiesAccepted = {(val) => setCookiesAccepted(val)}/>
          :
          <></>
        } */}
        
        <Switch>
          {/* APP */}
          <Route path='/dictionary/:id' component={(props) => <><Dictionary language={appLanguage} {...props} /></>} />
          <Route path='/basicDictionary/:id' component={(props) => <><BasicDictionary language={appLanguage} {...props} /></>}/>
          <Route 
            path="/profile/:id" 
            component={
              (props) => <>
                <Profile 
                  language={appLanguage} 
                  setLanguage={(val) => {
                    if (val === 'German' || val === 'Deutsch'){
                      setAppLanguage('de')
                    } else if(val === 'English' || val === 'Englisch') {
                      setAppLanguage('en')
                    }
                  }}
                  {...props} 
                />
              </>
            } 
          />
          <Route path='/changePassword' component={(props) => <><ChangePassword language={appLanguage} {...props} /></>} />
          <Route path='/sendFeedback' component={(props) => <><SendFeedback language={appLanguage} {...props} /></>} />
          <Route path='/upgradeAccount' component={(props) => <><UpgradeAccount language={appLanguage} {...props} /></>} />
          <Route path='/upgradeBookAccount' component={(props) => <><UpgradeBookAccount language={appLanguage} {...props} /></>} />
          <Route path='/confirmChangePassword' component={(props) => <><ConfirmChangePassword language={appLanguage} {...props} /></>} />
          {/* WEBSITE */}

          <Route path='/openDictionary/:id' component={(props) => <><OpenDictionary language={appLanguage} {...props} /></>} />

          <Route 
            path='/login'
            component={(props) => 
              <>
              <WebHeader 
                language={appLanguage} 
                setLanguage={(val) => {
                  if (val === 'German' || val === 'Deutsch'){
                    setAppLanguage('de')
                  } else if(val === 'English' || val === 'Englisch') {
                    setAppLanguage('en')
                  }
                }}
              />
              <Login 
                setAppLanguage={(val) => {
                  if (val === 'German' || val === 'Deutsch'){
                    setAppLanguage('de')
                  } else if(val === 'English' || val === 'Englisch') {
                    setAppLanguage('en')
                  }
                }}
                  language={appLanguage} 
                  {...props} 
              />
                
              <WebFooter language={appLanguage} />
            </>
              
            }
          >
          </Route>

          <Route
            path='/registration'
            component={(props) => 
            <>
            <WebHeader 
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
            />
            <Registration
              setAppLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
                language={appLanguage} 
                {...props} 
            />
            <WebFooter language={appLanguage} />
            </>
            }
          >

          </Route>
            
          {/* <Route path='/registration'>
            <WebHeader 
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
            />
            <Registration language={appLanguage} />
            <WebFooter language={appLanguage} />
          </Route> */}

          <Route path='/forgotpassword'>
            <WebHeader 
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
            />
            <ForgotPassword language={appLanguage} />
            <WebFooter language={appLanguage} />
          </Route>

          {/* <Route path="/resetpassword/:id" component={(props) => <><ResetPassword language={appLanguage} {...props} /></>} /> */}

          {/* <Route path='/resetpassword/:id'>
            <WebHeader 
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}            
            />
            <ResetPassword language={appLanguage}/>
            <WebFooter language={appLanguage}/>
          </Route> */}

          <Route 
            path='/resetpassword/:id'
            component={(props) => 
              <>
              <WebHeader 
                language={appLanguage} 
                setLanguage={(val) => {
                  if (val === 'German' || val === 'Deutsch'){
                    setAppLanguage('de')
                  } else if(val === 'English' || val === 'Englisch') {
                    setAppLanguage('en')
                  }
                }}
              />
              <ResetPassword 
              setAppLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
                console.log(val)
              }}
                language={appLanguage} 
                {...props} 
              />
                
              <WebFooter language={appLanguage} />
            </>
              
            }
          >
          </Route>

          <Route path='/confirmationRegistermail'>
            <WebHeader 
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}            
            />
            <ConfirmationRegistrationMail language={appLanguage}/>
            <WebFooter language={appLanguage}/>
          </Route>

          <Route 
            path='/confirmationRegister/:id'
            component={(props) => 
              <>
              <WebHeader 
                language={appLanguage} 
                setLanguage={(val) => {
                  if (val === 'German' || val === 'Deutsch'){
                    setAppLanguage('de')
                  } else if(val === 'English' || val === 'Englisch') {
                    setAppLanguage('en')
                  }
                }}
              />
              <ConfirmationRegistration
              setAppLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
                language={appLanguage} 
                {...props} 
              />
                
              <WebFooter language={appLanguage} />
            </>
              
            }
          >
          </Route>

          <Route path='/confirmationforgotpassword'>
            <WebHeader
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
            />
              <ConfirmationForgotPassword language={appLanguage}  />
            <WebFooter language={appLanguage}  />
          </Route>

          <Route path='/confirmationforgotpasswordmail'>
            <WebHeader 
                language={appLanguage} 
                setLanguage={(val) => {
                  if (val === 'German' || val === 'Deutsch'){
                    setAppLanguage('de')
                  } else if(val === 'English' || val === 'Englisch') {
                    setAppLanguage('en')
                  }
                }}
            />
            <ConfirmationForgotPasswordMail language={appLanguage} />
            <WebFooter language={appLanguage} />
          </Route>

          <Route exact path='/abouttheapp'>
            <WebHeader 
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
            />
            <AboutAppPage language={appLanguage} />
            <WebFooter language={appLanguage} />
          </Route>

          <Route exact path='/aboutthebook'>
            <WebHeader
               language={appLanguage} 
               setLanguage={(val) => {
                 if (val === 'German' || val === 'Deutsch'){
                   setAppLanguage('de')
                 } else if(val === 'English' || val === 'Englisch') {
                   setAppLanguage('en')
                 }
               }}
            />
            <AboutBookPage language={appLanguage} />
            <WebFooter language={appLanguage} />
          </Route>

          <Route exact path='/abouttheauthor'>
            <WebHeader 
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
            />
            <AboutAuthorPage language={appLanguage} />
            <WebFooter 
              language={appLanguage} 
            />
          </Route>

          <Route exact path='/imprint'>
            <WebHeader 
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
            />
            <ImprintPage language={appLanguage} />
            <WebFooter language={appLanguage} />
          </Route>

          <Route exact path='/privacyNotice'>
            <WebHeader 
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
            />
            <PrivacyNotice language={appLanguage} />
            <WebFooter language={appLanguage}  />
          </Route>

          <Route exact path='/termsOfUse'>
            <WebHeader
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }}
            />
            <TermsOfUse language={appLanguage}  />
            <WebFooter language={appLanguage}  />
          </Route>

          <Route exact path='/'>
            <WebHeader
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }} 
            />
            <HomePage language={appLanguage} />
            <WebFooter language={appLanguage} />
          </Route>

          <Route path="*">
            <WebHeader 
              language={appLanguage} 
              setLanguage={(val) => {
                if (val === 'German' || val === 'Deutsch'){
                  setAppLanguage('de')
                } else if(val === 'English' || val === 'Englisch') {
                  setAppLanguage('en')
                }
              }} 
            />
            <NotFoundPage language={appLanguage} />
            <WebFooter language={appLanguage} />
          </Route>
          
        </Switch>

      </Router>
    );
  
}
