import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import Button from '../Components/Button';
import Input from "../Components/Input";

import WebHeader from "../Components/WebHeader";
import WebFooter from "../Components/WebFooter";

// import backgroundImage from '../Images/AAZ-DesktopBackNude.png';
import loginImage from '../Images/Login_Desktop.png';

import ConfirmationRegistrationMail from './ConfirmationRegistrationMail';


import SvgPatternBackground from '../Components/svgPatternBackground';

import classes from '../Pages/Login.module.css'

import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

export default function Login(props, language) {
    const [pageContent, setPageContent] = useState({})
    const [credentials, setCredentials] = useState({
        pwd: '',
        txt: ''
    })
    const [enteredUserTouched, setEnteredUserTouched] = useState(false);
    const [enteredPwdTouched, setEnteredPwdTouched] = useState(false);

    const initError = {
        exists: false,
        helperText: null,
    };

    const [userError, setUserError] = useState(initError);
    const [pwdError, setPwdError] = useState(initError);
    
    const [passwordShown, setPasswordShown] = useState(false);

    const [confirmed, setConfirmed] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_STRAPI_URL}api/login-page/?locale=${props.language}`, { 
            'Content-Type': 'application/json' , method: 'GET' 
            })
            .then(data => data.json())
            .then(data => {
                setPageContent(data.data.attributes)
            })
    }, [props.language])

    useEffect(() => {
        if (!credentials.txt && enteredUserTouched) {
            setUserError({
              exists: true,
              helperText: `${pageContent.void_user_validation_text}`,
            });
        } else {
            setUserError({
              exists: false,
              helperText: null,
            });
        }
        if (!credentials.pwd && enteredPwdTouched) {
            setPwdError({
              exists: true,
              helperText: `${pageContent.void_password_validation_text}`,
            });
        } else {
            setPwdError({
              exists: false,
              helperText: null,
            });
        }
    }, [credentials, enteredUserTouched, enteredPwdTouched])

    function createMarkup(str) {
        return {__html: str};
    }

    const userIsValid = !userError.exists && enteredUserTouched;
    const pwdIsValid = !pwdError.exists && enteredPwdTouched;

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const userBlurHandler = (e) => {
        setEnteredUserTouched(true);
    };

    const pwdBlurHandler = (e) => {
        setEnteredPwdTouched(true);
    };

    const handleChange = (evt) => {
        let value = evt.target.value;
        let name = evt.target.name;
        setCredentials((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }
    const handleSubmit = (evt) => {
        evt.preventDefault();
        setEnteredUserTouched(true);
        setEnteredPwdTouched(true);


        if (!userIsValid) {
            return;
        }
        if (!pwdIsValid) {
            return;
        }

        const requestOptions = {
            method: "POST",
            body: JSON.stringify(credentials),
        }
        
        
        fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/login/`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log({...data}.user.token)
                window.localStorage.setItem("token", {...data}.user.token);
                const JWToken = {...data}.user.token

                fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/user/${data.user.id}`)
                    .then((response) => response.json())
                    .then((data) => {
                        // console.log({...data}.user)
                        
                        if (data.user.id === 0) {
                            setUserError({
                                exists: true,
                                helperText: `${pageContent.wrong_user_validation_text}`,
                            });
                            setPwdError({
                                exists: true,
                                helperText: `${pageContent.wrong_password_validation_text}`,
                            });
                            setEnteredUserTouched(true);
                            setEnteredPwdTouched(true);
                            return false;
                        } else if (data.user.confirmed === 1){
                            window.localStorage.setItem("jwt", JWToken);
                            window.localStorage.setItem("id", JSON.stringify(data.user.id));
                            window.localStorage.setItem("account", JSON.stringify(data.user.account));
                            
                            if(data.user.account === 2) {
                                props.history.push({
                                    pathname: "/dictionary/0",
                                })
                            } else if (data.user.account === 0) {
                                props.history.push({
                                    pathname: "/basicDictionary/0",
                                })
                            } 
                        } 
                        else if(data.user.confirmed === 0) {
                            setConfirmed(false)
                        }
                    })

                
            }
        )

    };

        return(
            <>
                {
                    confirmed
                    ?
                    <>
                        <SvgPatternBackground 
                            scale={window.innerWidth < 600 ? '1.5' : '1'} 
                            number={1} 
                            // height={'100vh'} 
                            height={window.innerWidth < 991 ? '896px' : '100vh'} 
                            color={'f6f1e7'} 
                            angle={45}
                        />
                        <div className={classes.LoginContentContainer}>   
                            <div className={classes.LoginContent}>
                                <div className={classes.LoginContentBox}>
                                    <div className={classes.LoginContentImageContainer}>
                                        <img src={loginImage} alt="" className={classes.LoginContentImage} />
                                    </div>
                                <div className={classes.SignIn}>
                                    <h3 className={classes.LoginContentTitle}>{pageContent.title_1}</h3>
                                    <form className={classes.LoginForm } onSubmit={handleSubmit}>
                                        <div style={{marginBottom:'10px'}} >
                                            <Input 
                                                title = {"email"}
                                                type = {"text"}
                                                name = {"txt"}
                                                placeholder = {pageContent.username_placeholder}
                                                value={credentials.txt}
                                                handleChange={handleChange}
                                                handleBlur={userBlurHandler}
                                                className={userError.exists ? "is-invalid": ""}
                                                errorDiv = {userError.exists ? "text-danger" : "no-danger"}
                                                errorMsg = {userError.helperText}
                                            />
                                        </div>
                                        <div style={{position:'relative'}} >
                                            <Input 
                                                title = {"password"}
                                                // type = {"password"}
                                                name = {"pwd"}
                                                placeholder = {pageContent.password_placehorlder}
                                                value={credentials.pwd}
                                                handleChange={handleChange}
                                                handleBlur={pwdBlurHandler}
                                                className={pwdError.exists ? "is-invalid": ""}
                                                errorDiv = {pwdError.exists ? "text-danger" : "no-danger"}
                                                errorMsg = {pwdError.helperText}
                                                type={passwordShown ? "text" : "password"}
                                            />
                                            <i style={{position: 'absolute', top:'8px',  right: window.innerWidth < 991 ? '5%' : '5%', cursor:'pointer'}} onClick={togglePasswordVisiblity}>{passwordShown ? EyeLogo() : EyeLogoClosed()}</i>
                                        </div>
                                        <div >
                                            <Link
                                                to={`/forgotpassword`}
                                                className={"link"}
                                            >
                                                {pageContent.forgot_password_title}
                                            </Link>
                                        </div>
                                        <div className={classes.LoginButtonContainer}>
                                            <Button
                                                title={pageContent.button_text}
                                                className={"ochre"}
                                            />
                                        </div>
                                    </form>
                                </div>
                            <div className={classes.DivisionLine}></div>
                            <div  className={classes.LoginOtherOptions}>
                                <div style={{textAlign:'center'}} className='subtitle'>{pageContent.or_text}</div>
                                <div className={classes.LoginGoogleContainer} >
                                    <GoogleLogin
                                        onSuccess={credentialResponse => {
                                            // console.log(credentialResponse);
                                            var decoded = jwt_decode(credentialResponse.credential);
                                            // console.log(decoded)
                                            const requestOptions = {
                                                method: "POST",
                                                body: JSON.stringify({
                                                    username: '',
                                                    name: decoded.given_name,
                                                    lastname: decoded.family_name,
                                                    email: decoded.email,
                                                    pwd: '',
                                                    picture:  decoded.picture    
                                                }),
                                            };
                                            // console.log(requestOptions)

                                            if (props.language === "de") {
                                                fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/googleauth/`, requestOptions)
                                                .then(response => response.json())
                                                .then(data => {
                                                    // console.log(data);
                                                    window.localStorage.setItem("jwt", {...data}.user.token);
                                                    window.localStorage.setItem("id", JSON.stringify(data.user.id));
                                                    window.localStorage.setItem("account", JSON.stringify(data.user.account));
                                                    if(data.user.account === 2) {
                                                        props.history.push({
                                                            pathname: "/dictionary/0",
                                                        })
                                                    } else if (data.user.account === 0) {
                                                        props.history.push({
                                                            pathname: "/basicDictionary/0",
                                                        })
                                                    } 
                                                });
                                            } else {
                                                fetch(`${process.env.REACT_APP_API_GOLANG_URL}/v1/googleauthEN/`, requestOptions)
                                                .then(response => response.json())
                                                .then(data => {
                                                    // console.log(data);
                                                    window.localStorage.setItem("jwt", {...data}.user.token);
                                                    window.localStorage.setItem("id", JSON.stringify(data.user.id));
                                                    window.localStorage.setItem("account", JSON.stringify(data.user.account));
                                                    if(data.user.account === 2) {
                                                        props.history.push({
                                                            pathname: "/dictionary/0",
                                                        })
                                                    } else if (data.user.account === 0) {
                                                        props.history.push({
                                                            pathname: "/basicDictionary/0",
                                                        })
                                                    } 
                                                });
                                            }

                                            
                                            
                                        }}
                                    />
                                </div> 
                                <div >
                                    <div style={{textAlign:'center'}} className='subtitle'>
                                        {pageContent.account_title}
                                    </div>
                                </div>
                                <div  className=''>
                                        <Link
                                            to={`/registration`}
                                            className={"link"}
                                        >
                                            {pageContent.register_text}
                                        </Link>
                                    </div>
                            </div>
                            </div>
                                

                            </div>
                        </div>
                    </>
                    :
                    <>
                    <div style={{height: '92vh', display:'grid', justifyContent:'center', alignContent:'center', justifyItems:'center'}}>
                        <div style={{display:'grid', textAlign:'center'}} >
                            <div >
                                <h3 style={{textTransform:'uppercase'}}>
                                    {/* {
                                        props.language === "de"
                                        ?
                                        <>Oh-oh! Sie sind <br/> bereits registriert</>
                                        :
                                        <>Uh-oh! You are <br/> already registered</>
                                    }  */}
                                    <span dangerouslySetInnerHTML={createMarkup(pageContent.no_email_validation_title)}/>
                                </h3>
                            </div>
                            <div>
                                <i className='icon-confirm icon-speaker'></i>
                            </div>
                            <div style={{padding:'0 10px'}} className='body_text'>
                                <div style={{fontWeight:'bold'}} >
                                    
                                    {/* {
                                        props.language === "de"
                                        ?
                                        <>Sie haben bereits ein Konto, haben es aber noch nicht aktiviert.</>
                                        :
                                        <>You already have an account but have not activated it yet.</>
                                    }  */}
                                    {pageContent.no_email_validation}
                                </div>
                                <div>
                                    
                                    {/* {
                                        props.language === "de"
                                        ?
                                        <>Bitte gehen Sie zu Ihrer E-Mail, um Ihr Konto über die E-Mail, die wir Ihnen gerade gesendet haben, zu bestätigen und zu aktivieren.</>
                                        :
                                        <>Please go to your email to confirm and activate your account via the email we have just sent you.</>
                                    }  */}
                                    {pageContent.no_email_validation_action}
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                }
                
                
            </>
        );
}
const EyeLogo = () => {
    return(
      <svg width="24" height="24" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0125 15.75C5.94249 15.75 1.5325 12.65 0.0225 8.02997C-0.0075 7.92997 -0.0075 7.81997 0.0225 7.71997C1.5325 3.09997 5.94249 0 11.0125 0C16.0825 0 20.4925 3.09997 22.0025 7.71997C22.0325 7.81997 22.0325 7.92997 22.0025 8.02997C20.4925 12.65 16.0825 15.75 11.0125 15.75ZM1.0225 7.87C2.4425 11.99 6.43249 14.75 11.0125 14.75C15.5925 14.75 19.5825 11.99 21.0025 7.87C19.5825 3.75 15.5925 0.98999 11.0125 0.98999C6.43249 0.98999 2.4425 3.75 1.0225 7.87Z" fill="#B66A00"/>
        <path d="M11.0125 12.7999C8.30251 12.7999 6.09253 10.5899 6.09253 7.87994C6.09253 7.59994 6.31253 7.37994 6.59253 7.37994C6.87253 7.37994 7.09253 7.59994 7.09253 7.87994C7.09253 10.0399 8.85251 11.7999 11.0125 11.7999C13.1725 11.7999 14.9326 10.0399 14.9326 7.87994C14.9326 5.71994 13.1725 3.95996 11.0125 3.95996C10.7325 3.95996 10.5125 3.73996 10.5125 3.45996C10.5125 3.17996 10.7325 2.95996 11.0125 2.95996C13.7225 2.95996 15.9326 5.16994 15.9326 7.87994C15.9326 10.5899 13.7225 12.7999 11.0125 12.7999Z" fill="#B66A00"/>
      </svg>
    )
}

const EyeLogoClosed = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.0125 19.75C6.94249 19.75 2.5325 16.65 1.0225 12.03C0.9925 11.93 0.9925 11.82 1.0225 11.72C2.5325 7.09997 6.94249 4 12.0125 4C17.0825 4 21.4925 7.09997 23.0025 11.72C23.0325 11.82 23.0325 11.93 23.0025 12.03C21.4925 16.65 17.0825 19.75 12.0125 19.75ZM2.0225 11.87C3.4425 15.99 7.43249 18.75 12.0125 18.75C16.5925 18.75 20.5825 15.99 22.0025 11.87C20.5825 7.75 16.5925 4.98999 12.0125 4.98999C7.43249 4.98999 3.4425 7.75 2.0225 11.87Z" fill="#B66A00"/>
            <path d="M12.0125 16.7899C9.30251 16.7899 7.09253 14.5799 7.09253 11.8699C7.09253 11.5899 7.31253 11.3699 7.59253 11.3699C7.87253 11.3699 8.09253 11.5899 8.09253 11.8699C8.09253 14.0299 9.85251 15.7899 12.0125 15.7899C12.2925 15.7899 12.5125 16.0099 12.5125 16.2899C12.5125 16.5699 12.2925 16.7899 12.0125 16.7899Z" fill="#B66A00"/>
            <path d="M16.4225 12.3799C16.1425 12.3799 15.9225 12.1599 15.9225 11.8799C15.9225 9.71994 14.1624 7.95996 12.0024 7.95996C11.7224 7.95996 11.5024 7.73996 11.5024 7.45996C11.5024 7.17996 11.7224 6.95996 12.0024 6.95996C14.7124 6.95996 16.9225 9.16994 16.9225 11.8799C16.9225 12.1599 16.7025 12.3799 16.4225 12.3799Z" fill="#B66A00"/>
            <path d="M19.1426 19.51C19.0126 19.51 18.8826 19.46 18.7926 19.36L4.53257 5.09995C4.33257 4.89995 4.33257 4.58999 4.53257 4.38999C4.73257 4.18999 5.04259 4.18999 5.24259 4.38999L19.5025 18.65C19.7025 18.85 19.7025 19.16 19.5025 19.36C19.4025 19.46 19.2726 19.51 19.1526 19.51H19.1426Z" fill="#B66A00"/>
        </svg>
    )
}
